import { Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEntity, useEntityField, Defaults, useAuth, Task } from "@emberly/zenith-client";
import { useCallback, useState } from "react";
import moment from "moment/moment";
import RequestInformationCard from "./information/RequestInformationCard";
import { CalcToPaySum, GetUserReference, UpdateSender } from "../../../../common/orders";
import ReceiptDialog from "../dialogs/ReceiptDialog";
import useReceipt from "../../../../hooks/useReceipt";
import ConfirmDialog from "../../inputs/ConfirmDialog";
import { useMission } from "../../../../providers/MissionProvider";
import { useStation } from "../../../../providers/StationProvider";
import { useOrder } from "../../../../providers/OrderProvider";
import { ActivityEnums } from "../../../../common/constants";
import { MakeActivity } from "../../../../common/activity";
import MultilineButton from "../../inputs/MultilineButton";

export default function RequestFlow() {
  const { t } = useTranslation();
  const { updateEntityField, entity } = useEntity();
  const { user } = useAuth();
  const { hasValue } = useOrder();

  const { sharePDF } = useReceipt();
  const service = useEntityField("payment.request.service", Defaults.String);
  const requested = useEntityField("payment.request.requested", Defaults.Boolean);
  const confirmed = useEntityField("payment.request.confirmed", Defaults.Boolean);
  const breakpoint = requested.value || confirmed.value ? 12 : 6;
  const { order } = useOrder();
  const { orders } = useMission();
  const { priceUnit, isAccountant, logEvent } = useStation();

  const [showReceipt, setShowReceipt] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);
  const [requestAction, setRequestAction] = useState(null);

  const onRequest = useCallback(async () => {
    try {

      const t = new Task();
      setRequestAction(t);
      await t.wait();

      requested.onChange(null, true);
      updateEntityField("payment.request.requestedDate", moment.utc().toISOString());
      updateEntityField("payment.calculatedTotal", { value: CalcToPaySum(order, orders), currency: priceUnit });
      UpdateSender(user, updateEntityField);

      logEvent(MakeActivity(ActivityEnums.Category.Billing, ActivityEnums.Type.PaymentRequested, `${entity?.missionId}/${entity?.id}`));

    } catch { } finally {
      setRequestAction(null);
    }
  }, [updateEntityField, requested, user, order, orders, priceUnit, logEvent, entity]);

  const onConfirm = useCallback(async () => {
    try {

      const t = new Task();
      setConfirmAction(t);
      await t.wait();

      confirmed.onChange(null, true);
      updateEntityField("payment.request.confirmedDate", moment.utc().toISOString());
      updateEntityField("payment.request.confirmedBy", GetUserReference(user));
      updateEntityField("payment.calculatedTotal", { value: CalcToPaySum(order, orders), currency: priceUnit });
      UpdateSender(user, updateEntityField);

      logEvent(MakeActivity(ActivityEnums.Category.Billing, ActivityEnums.Type.PaymentConfirmed, `${entity?.missionId}/${entity?.id}`));

    } catch { } finally {
      setConfirmAction(null);
    }
  }, [updateEntityField, confirmed, user, order, orders, priceUnit, logEvent, entity]);

  return (
    <>

      {
        requested.value || confirmed.value ? (
          <Grid item xs={12}>
            <RequestInformationCard />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <TextField
              label={t("order:requestFlow:service")}
              variant="filled"
              multiline
              fullWidth
              size="small"
              value={service.value}
              onChange={service.onChange}
            />
          </Grid>
        )
      }

      {
        !requested.value && !confirmed.value ? (
          <Grid item xs={breakpoint}>
            <MultilineButton fullWidth variant="outlined" color="primary" onClick={onRequest} disabled={!hasValue}>
              {t("order:requestFlow:requestPayment")}
            </MultilineButton>
          </Grid>
        ) : null
      }

      {
        !confirmed.value ? (
          <Grid item xs={breakpoint}>
            <MultilineButton fullWidth variant="contained" color="primary" onClick={onConfirm} disabled={!isAccountant || !hasValue}>
              {t("order:requestFlow:confirmPayment")}
            </MultilineButton>
          </Grid>
        ) : (
          <>
            <Grid item xs={6}>
              <MultilineButton fullWidth variant="outlined" color="primary" onClick={() => setShowReceipt(true)}>
                {t("order:requestFlow:viewReceipt")}
              </MultilineButton>
            </Grid>

            <Grid item xs={6}>
              <MultilineButton fullWidth variant="contained" color="primary" onClick={sharePDF}>
                {t("order:requestFlow:sendReceipt")}
              </MultilineButton>
            </Grid>
          </>
        )
      }

      {showReceipt ? (
        <ReceiptDialog onClose={() => setShowReceipt(false)} />
      ) : null}

      <ConfirmDialog
        task={confirmAction}
        title={t("order:paymentConfirmedDialog:title")}
        description={t("order:paymentConfirmedDialog:description")}
        confirmText={t("order:paymentConfirmedDialog:confirmText")}
      />

      <ConfirmDialog
        task={requestAction}
        title={t("order:paymentRequestedDialog:title")}
        description={t("order:paymentRequestedDialog:description")}
        confirmText={t("order:paymentRequestedDialog:confirmText")}
      />

    </>
  );
}
