import { makeStyles } from "tss-react/mui";
import { Grid, Button } from "@mui/material";
import { useEffect, useRef } from "react";
import { useDevice } from "../../../providers/DeviceProvider";

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      position: "relative",
      height: "48px",
      width: "100%",
      borderRadius: "4px",
      padding: "4px",
      background: theme.palette.common.grey.background,
      overflow: "hidden"
    },
    slider: {
      position: "absolute",
      height: "40px",
      top: "4px",
      left: "0px",
      width: "calc(100% - 8px)",
      borderRadius: "4px",
      border: `2px solid ${theme.palette.primary.main}`,
      background: theme.palette.primary.contrast,
      zIndex: 0
    },
    button: {
      zIndex: 2,
      flex: "1 1 0px",
      fontSize: "16px"
    },
    skeletonLoader: {
      zIndex: 3,
      position: "absolute",
      top: 0,
      left: 0,
    }
  })
);

export default function HorizontalSelector(props) {
  const { items, value, onChange, className, loading } = props;
  const { classes } = useStyles();
  const sliderRef = useRef(null);
  const { screenWidth } = useDevice();

  useEffect(() => {
    if (!!sliderRef.current && items.length > 0 && !loading) {
      const slider = sliderRef.current;
      const parent = slider.parentElement;
      const siblings = parent.children;
      const selected = siblings[value];
      slider.style.width = `calc(${100 / items.length}% - 4px)`;
      slider.style.transform = `translateX(${selected.offsetLeft}px)`;
      
      const timer = setTimeout(() => {
        if (!!slider) {
          slider.style.transition = "transform 0.2s";
        }
      }, 10);

      return () => {
        clearTimeout(timer);
      }
    }
  }, [value, items.length, value, screenWidth, sliderRef.current, loading]);

  return (
    <Grid container direction="row" justifyContent="space-evenly" alignItems="center" className={`${classes.root} ${className || ""}`}>
      {items.map((item, key) => (
        <Button key={key} className={classes.button} onClick={() => onChange(key, item)} disabled={loading}>{item}</Button>
      ))}
      {
        !loading ? <div className={classes.slider} ref={sliderRef}></div> : null
      }
    </Grid>
  );
}