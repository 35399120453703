import { Suspense, useEffect, useMemo, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Box } from "@mui/material";
import Sidebar from "./components/common/navigation/Sidebar";
import { useDevice } from "./providers/DeviceProvider";
import { Routes, Route } from "react-router";
import DispatchPage from "./components/pages/dispatch/DispatchPage";
import NotFoundPage from "./components/pages/error/NotFoundPage";
import MissionPage from "./components/pages/mission/MissionPage";
import CauseRegister from "./components/pages/registers/CauseRegister";
import ServiceVehicleRegister from "./components/pages/registers/ServiceVehicleRegister";
import MissionTagRegister from "./components/pages/registers/MissionTagRegister";
import ContactRegister from "./components/pages/contacts/ContactRegister";
import ContactPage from "./components/pages/contacts/ContactPage";
import ProductRegister from "./components/pages/registers/ProductRegister";
import AgreementRegister from "./components/pages/agreements/AgreementRegister";
import AgreementPage from "./components/pages/agreements/AgreementPage";
import DriverRegister from "./components/pages/drivers/DriverRegister";
import StationSettingsPage from "./components/pages/settings/StationSettingsPage";
import WarehouseRegister from "./components/pages/registers/WarehouseRegister";
import DraftsPage from "./components/pages/drafts/DraftsPage";
import ActiveMissionsPage from "./components/pages/activemissions/ActiveMissionsPage";
import StoragePage from "./components/pages/storage/StoragePage";
import MyMissionsPage from "./components/pages/mymissions/MyMissionsPage";
import MissionTaskRoot from "./components/routers/MissionTaskRoot";
import MyCashSettlementPage from "./components/pages/cashsettlement/MyCashSettlementPage";
import CashSettlementOrdersPage from "./components/pages/cashsettlement/subpages/CashSettlementOrdersPage";
import MyCashSettlementArchivePage from "./components/pages/cashsettlement/subpages/MyCashSettlementArchivePage";
import CashSettlementPage from "./components/pages/cashsettlement/CashSettlementPage";
import { OrderPage } from "./components/pages/order/OrderPage";
import CashSettlementsPage from "./components/pages/cashsettlement/CashSettlementsPage";
import CashOrdersPage from "./components/pages/cashorders/CashOrdersPage";
import VatCodeRegister from "./components/pages/registers/VatCodeRegister";
import ProductGroupRegister from "./components/pages/registers/ProductGroupRegister";
import ExportSettingsPage from "./components/pages/settings/ExportSettingsPage";
import CashExportPage from "./components/pages/cashorders/CashExportPage";
import InvoicingPage from "./components/pages/invoicing/InvoicingPage";
import InvoiceExportPage from "./components/pages/invoicing/InvoiceExportPage";
import OrdersPage from "./components/pages/orders/OrdersPage";
import HomePage from "./components/pages/home/HomePage";
import ActivityLogPage from "./components/pages/log/ActivityLogPage";
import { useStation } from "./providers/StationProvider";
import ActivityLogPane from "./components/pages/log/ActivityLogPane";
import AllMissionsPage from "./components/pages/allmissions/AllMissionsPage";
import { useSignal } from "@emberly/zenith-client";
import OfflinePage from "./components/pages/error/OfflinePage";
import StatisticsPage from "./components/pages/statistics/StatisticsPage";
import IntegrationsPage from "./components/pages/integrations/IntegrationsPage";
import AllTasksPage from "./components/pages/alltasks/AllTasksPage";
import { DriverProvider } from "./providers/DriverProvider";

const drawerWidth = "280px";
const logWidth = "348px";

// grey 120deg linear-gradient(120deg, #e4e4e4 0%, #cacccd 100%)

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      width: "100%",
      height: "100%",
      position: "absolute",
      background: "rgb(240, 242, 245)", //theme.palette.background.grey
    },
    sidebarMobile: {
      "& .MuiDrawer-paper": {
        width: "80vw",
      }
    },
    sidebarDesktop: {
      "& .MuiDrawer-paper": {
        width: drawerWidth
      },
      flexShrink: 0
    },
    contentMobile: {
      width: "100%",
      height: "100%",
    },
    contentDesktop: {
      position: "relative",
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth})`,
      height: "100%",
    },
    contentDesktopLog: {
      position: "relative",
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth} - ${logWidth})`,
      height: "100%",
    },
    activityLog: {
      position: "absolute",
      width: logWidth,
      height: "100%",
      right: 0,
      top: 0
    },
  })
);

export default function App() {
  const { classes } = useStyles();
  const { isMobilePortraitView, slim, sidebarOpen, setSidebarOpen, isMobile } = useDevice();
  const { showActivityLog, isDriver } = useStation();
  const { connecting } = useSignal();
  const [isOffline, setIsOffline] = useState(false);


  useEffect(() => {
    if (connecting) {
      const timer = setTimeout(() => setIsOffline(true), 5000);

      return () => {
        clearTimeout(timer);
        setIsOffline(false);
      };
    }
  }, [connecting]);

  useMemo(() => {
    const path = window?.location?.pathname;
    if (isMobile && path === "/" && isDriver) {
      window.location.replace("/mymissions");
    }
  }, [isDriver, isMobile]);

  return isOffline ? (
    <OfflinePage />
  ) : (
    <DriverProvider>
      <div className={classes.root}>
        <Sidebar
          className={isMobilePortraitView ? classes.sidebarMobile : classes.sidebarDesktop}
          open={sidebarOpen}
          setOpen={setSidebarOpen}
        />
        <Box className={slim ? classes.contentMobile : (showActivityLog ? classes.contentDesktopLog : classes.contentDesktop)}>
          <Suspense>
            <Routes>

              <Route path="/" element={<HomePage />} />
              <Route path="/log" element={<ActivityLogPage />} />

              <Route path="/dispatch" element={<DispatchPage />} />
              <Route path="/dispatch/:missionId" element={<MissionPage />} />
              <Route path="/dispatch/:missionId/:taskNumber" element={<MissionPage />} />

              <Route path="/drafts" element={<DraftsPage />} />
              <Route path="/drafts/:missionId" element={<MissionPage />} />
              <Route path="/drafts/:missionId/:taskNumber" element={<MissionPage />} />

              <Route path="/activemissions" element={<ActiveMissionsPage />} />
              <Route path="/activemissions/:missionId" element={<MissionPage />} />
              <Route path="/activemissions/:missionId/:taskNumber" element={<MissionPage />} />


              <Route path="/missions" element={<AllMissionsPage />} />
              <Route path="/missions/:missionId" element={<MissionPage />} />
              <Route path="/missions/:missionId/:taskNumber" element={<MissionPage />} />

              <Route path="/tasks" element={<AllTasksPage />} />
              <Route path="/tasks/:missionId/" element={<MissionPage />} />
              <Route path="/tasks/:missionId/:taskNumber" element={<MissionPage />} />

              <Route path="/storage" element={<StoragePage />} />
              <Route path="/storage/:missionId" element={<MissionPage />} />
              <Route path="/storage/:missionId/:taskNumber" element={<MissionPage />} />

              <Route path="/causes" element={<CauseRegister />} />
              <Route path="/warehouses" element={<WarehouseRegister />} />
              <Route path="/vatcodes" element={<VatCodeRegister />} />
              <Route path="/productgroups" element={<ProductGroupRegister />} />
              <Route path="/vehicles" element={<ServiceVehicleRegister />} />
              <Route path="/tags" element={<MissionTagRegister />} />
              <Route path="/products" element={<ProductRegister />} />


              <Route path="/contacts" element={<ContactRegister />} />
              <Route path="/contacts/:tabId" element={<ContactRegister />} />

              <Route path="/contacts/:tabId/:contactId/:tabId" element={<ContactPage />} />
              <Route path="/contacts/:tabId/:contactId/missions/:missionId" element={<MissionPage />} />
              <Route path="/contacts/:tabId/:contactId/orders/:missionId/" element={<MissionPage />} />



              <Route path="/contacts/:tabId/:contactId/:tabId" element={<ContactPage />} />
              <Route path="/contacts/:tabId/:contactId/missions/:missionId" element={<MissionPage />} />
              <Route path="/contacts/:tabId/:contactId/orders/:missionId/" element={<MissionPage />} />


              <Route path="/agreements" element={<AgreementRegister />} />
              <Route path="/agreements/:agreementId" element={<AgreementPage />} />
              <Route path="/drivers" element={<DriverRegister />} />

              <Route path="/mymissions" element={<MyMissionsPage />} />
              <Route path="/mymissions/:missionId/:taskId/*" element={<MissionTaskRoot />} />

              <Route path="/mycashsettlement" element={<MyCashSettlementPage />} />
              <Route path="/mycashsettlement/orders" element={<CashSettlementOrdersPage />} />
              <Route path="/mycashsettlement/orders/:missionId/:orderId" element={<OrderPage />} />
              <Route path="/mycashsettlement/orders/:missionId/:orderId/mission" element={<MissionPage />} />
              <Route path="/mycashsettlement/orders/:missionId/:orderId/mission/:taskNumber" element={<MissionPage />} />


              <Route path="/mycashsettlement/archive" element={<MyCashSettlementArchivePage />} />
              <Route path="/mycashsettlement/archive/:settlementId" element={<CashSettlementPage />} />
              <Route path="/mycashsettlement/archive/:settlementId/orders" element={<CashSettlementOrdersPage />} />
              <Route path="/mycashsettlement/archive/:settlementId/orders/:missionId/:orderId" element={<OrderPage />} />
              <Route path="/mycashsettlement/archive/:settlementId/orders/:missionId/:orderId/mission" element={<MissionPage />} />
              <Route path="/mycashsettlement/archive/:settlementId/orders/:missionId/:orderId/mission/:taskNumber" element={<MissionPage />} />


              <Route path="/cashsettlements" element={<CashSettlementsPage />} />
              <Route path="/cashsettlements/:tabId" element={<CashSettlementsPage />} />
              <Route path="/cashsettlements/:tabId/:settlementId" element={<CashSettlementPage />} />
              <Route path="/cashsettlements/:tabId/:settlementId/orders" element={<CashSettlementOrdersPage />} />
              <Route path="/cashsettlements/:tabId/:settlementId/orders/:missionId/:orderId" element={<OrderPage />} />
              <Route path="/cashsettlements/:tabId/:settlementId/orders/:missionId/:orderId/mission" element={<MissionPage />} />
              <Route path="/cashsettlements/:tabId/:settlementId/orders/:missionId/:orderId/mission/:taskNumber" element={<MissionPage />} />

              <Route path="/cashorders" element={<CashOrdersPage />} />
              <Route path="/cashorders/:tabId" element={<CashOrdersPage />} />
              <Route path="/cashorders/requested/:missionId/:orderId" element={<OrderPage />} />
              <Route path="/cashorders/requested/:missionId/:orderId/mission" element={<MissionPage />} />
              <Route path="/cashorders/requested/:missionId/:orderId/mission/:taskNumber" element={<MissionPage />} />

              <Route path="/cashorders/ready/:missionId/:orderId" element={<OrderPage />} />
              <Route path="/cashorders/ready/:missionId/:orderId/mission" element={<MissionPage />} />
              <Route path="/cashorders/ready/:missionId/:orderId/mission/:taskNumber" element={<MissionPage />} />

              <Route path="/cashorders/journals/:exportId" element={<CashExportPage />} />
              <Route path="/cashorders/journals/:exportId/:missionId/:orderId" element={<OrderPage />} />
              <Route path="/cashorders/journals/:exportId/:missionId/:orderId/mission" element={<MissionPage />} />
              <Route path="/cashorders/journals/:exportId/:missionId/:orderId/mission/:taskNumber" element={<MissionPage />} />


              <Route path="/cashorders/journalentries/:exportId" element={<CashExportPage />} />
              <Route path="/cashorders/journalentries/:exportId/:missionId/:orderId" element={<OrderPage />} />
              <Route path="/cashorders/journalentries/:exportId/:missionId/:orderId/mission" element={<MissionPage />} />
              <Route path="/cashorders/journalentries/:exportId/:missionId/:orderId/mission/:taskNumber" element={<MissionPage />} />

              <Route path="/invoicing" element={<InvoicingPage />} />
              <Route path="/invoicing/:tabId" element={<InvoicingPage />} />

              <Route path="/invoicing/open/:missionId/:orderId" element={<OrderPage />} />
              <Route path="/invoicing/open/:missionId/:orderId/mission" element={<MissionPage />} />
              <Route path="/invoicing/open/:missionId/:orderId/mission/:taskNumber" element={<MissionPage />} />

              <Route path="/invoicing/ready/:missionId/:orderId" element={<OrderPage />} />
              <Route path="/invoicing/ready/:missionId/:orderId/mission" element={<MissionPage />} />
              <Route path="/invoicing/ready/:missionId/:orderId/mission/:taskNumber" element={<MissionPage />} />

              <Route path="/invoicing/journals/:exportId" element={<InvoiceExportPage />} />
              <Route path="/invoicing/journals/:exportId/:missionId/:orderId" element={<OrderPage />} />
              <Route path="/invoicing/journals/:exportId/:missionId/:orderId/mission" element={<MissionPage />} />
              <Route path="/invoicing/journals/:exportId/:missionId/:orderId/mission/:taskNumber" element={<MissionPage />} />

              <Route path="/invoicing/journalentries/:exportId" element={<InvoiceExportPage />} />
              <Route path="/invoicing/journalentries/:exportId/:missionId/:orderId" element={<OrderPage />} />
              <Route path="/invoicing/journalentries/:exportId/:missionId/:orderId/mission" element={<MissionPage />} />
              <Route path="/invoicing/journalentries/:exportId/:missionId/:orderId/mission/:taskNumber" element={<MissionPage />} />


              <Route path="/orders" element={<OrdersPage />} />
              <Route path="/orders/:tabId" element={<OrdersPage />} />
              <Route path="/orders/:tabId/:missionId/:orderId" element={<OrderPage />} />
              <Route path="/orders/:tabId/:missionId/:orderId/mission" element={<MissionPage />} />
              <Route path="/orders/:tabId/:missionId/:orderId/mission/:taskNumber" element={<MissionPage />} />


              <Route path="/station/settings" element={<StationSettingsPage />} />
              <Route path="/station/export" element={<ExportSettingsPage />} />
              <Route path="/station/integrations" element={<IntegrationsPage />} />

              <Route path="/statistics" element={<StatisticsPage />} />

              <Route path="*" element={<NotFoundPage setSidebarOpen={setSidebarOpen} />} />

            </Routes>
          </Suspense>
        </Box>
        {showActivityLog ? (
          <Box className={classes.activityLog}>
            <ActivityLogPane />
          </Box>
        ) : null}
      </div>
    </DriverProvider>
  );
}