import { makeStyles } from "tss-react/mui";
import { Grid, Typography, Button, Table, TableBody, TableRow, InputAdornment, Dialog, DialogTitle, DialogContent, DialogActions, TableCell } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEntity, useEntities } from "@emberly/zenith-client";
import { useState } from "react";
import AgreementProductPicker from "../AgreementProductPicker";
import { useStation } from "../../../../providers/StationProvider";
import { GetVatRate, MakeOrderLineItem } from "../../../../common/orders";
import { OrderEnums, OrderEnumsLists } from "../../../../common/constants";
import TextFieldDecimal from "../../inputs/TextFieldDecimal";

const useStyles = makeStyles()(
  (theme) => ({
    table: {
      width: "100%",
      "& td": {
        border: "none",
        padding: theme.spacing(1),
        paddingLeft: 0,
        paddingBottom: theme.spacing(0.25),
        paddingTop: theme.spacing(0.25),
      }
    }
  })
);

export default function ReferencedOrderLineItemDialog(props) {
  const { edit, onClose, index, orderLine } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { pushToList, updateEntityField, spliceList } = useEntity();
  const { priceUnit } = useStation();
  const [searchProductItem, setSearchProductItem] = useState(edit && !!orderLine ? orderLine : null);
  const [price, setPrice] = useState(edit && !!orderLine ? orderLine.price.value : "0");
  const [quantity, setQuantity] = useState(edit && !!orderLine ? orderLine.quantity : "1");
  const [discount, setDiscount] = useState(edit && !!orderLine ? orderLine.discount : "0");
  const [currency, setCurrency] = useState(edit && !!orderLine ? orderLine.price.currency : priceUnit);

  const { entities: vatCodes } = useEntities("VatCode");
  const { entities: productGroups } = useEntities("ProductGroup");

  const onSubmit = () => {
    const item = MakeOrderLineItem(searchProductItem, { value: price, currency }, quantity, discount, productGroups, vatCodes);
    if (edit) {
      updateEntityField(`orderLines.${index}`, item);
    } else {
      pushToList("orderLines", item);
    }
    onClose();
  };

  const onDelete = () => {
    spliceList("orderLines", index);
    onClose();
  };


  const onSearch = (product) => {
    setSearchProductItem(product);

    if (!!product) {
      setCurrency(product.price.currency !== OrderEnums.Currency.None ? product.price.currency : priceUnit);
      setQuantity("1");

      if (product.discounted) {
        setPrice(product.discount.value);
      } else {
        setPrice(product.price.value);
      }

    } else {
      setPrice("0");
      setDiscount("0");
      setQuantity("1");
    }
  };

  return (
    <Dialog
      open
      onClose={onClose}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>
        {edit ? t("order:referencedOrderLineItemDialog:editTitle") : t("order:referencedOrderLineItemDialog:createTitle")}
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={1}>

          <Grid item xs={12}>
            <AgreementProductPicker
              label={t("order:referencedOrderLineItemDialog:search")}
              onChange={onSearch}
              value={searchProductItem}
              path="name"
            />
          </Grid>

          <Grid item xs={12}>
            <TextFieldDecimal
              label={t("order:referencedOrderLineItemDialog:price")}
              onChange={ev => setPrice(ev.target.value)}
              value={price}
              variant="filled"
              fullWidth
              size="small"
              InputProps={{
                endAdornment: <InputAdornment position="start">{t(`order:enums:currencyShortHand:${OrderEnumsLists.Currency[currency]}`)}</InputAdornment>
              }}
            />
          </Grid>


          <Grid item xs={12}>
            <TextFieldDecimal
              label={t("order:referencedOrderLineItemDialog:quantity")}
              onChange={ev => setQuantity(ev.target.value)}
              value={quantity}
              variant="filled"
              fullWidth
              size="small"
            />
          </Grid>

          <Grid item xs={12}>
            <TextFieldDecimal
              label={t("order:referencedOrderLineItemDialog:discount")}
              onChange={ev => setDiscount(ev.target.value)}
              value={discount}
              variant="filled"
              fullWidth
              size="small"
              InputProps={{
                endAdornment: <InputAdornment position="start">%</InputAdornment>
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Table className={classes.table}>
              <TableBody>

                <TableRow>
                  <TableCell>
                    <Typography variant="body2" color="textSecondary">{t("order:referencedOrderLineItemDialog:vat")}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">{!!searchProductItem?.productGroup ? `${GetVatRate(searchProductItem.productGroup.id, productGroups, vatCodes)}%` : "-"}</Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography variant="body2" color="textSecondary">{t("order:referencedOrderLineItemDialog:code")}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">{searchProductItem?.code || "-"}</Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography variant="body2" color="textSecondary">{t("order:referencedOrderLineItemDialog:description")}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">{searchProductItem?.description || "-"}</Typography>
                  </TableCell>
                </TableRow>

              </TableBody>
            </Table>
          </Grid>

        </Grid>
      </DialogContent>
      <DialogActions>
        {!!edit ? (
          <Button onClick={onDelete}>
            {t("order:referencedOrderLineItemDialog:delete")}
          </Button>
        ) : null}
        <Button onClick={onClose} variant={!!edit ? "outlined" : undefined}>
          {t("order:referencedOrderLineItemDialog:cancel")}
        </Button>
        <Button variant="contained" color="primary" onClick={onSubmit} disabled={!searchProductItem}>
          {edit ? t("order:referencedOrderLineItemDialog:edit") : t("order:referencedOrderLineItemDialog:create")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}


