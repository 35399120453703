import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./i18n.js";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import config from "./config";
import { store } from "./store";
import StationContextProvider from "./providers/StationProvider";
import mapboxgl from "mapbox-gl";
import { ThemeConfig } from "@assist/theme";
import EventEmitter from "events";
import "mapbox-gl/dist/mapbox-gl.css";
import { DeviceProvider } from "./providers/DeviceProvider";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/nb";
import { NotificationContextProvider } from "./providers/NotificationProvider.jsx";
import { LicenseInfo } from "@mui/x-license-pro";
import { UPDATE_AVAILABLE, WORKER_INIT } from "./store/actions.js";
import { ZenithAppContextProvider } from "@emberly/zenith-client";
import { SnackbarProvider } from "notistack";
import moment from "moment/moment";


// Locale fromNow
moment.updateLocale("nb", {
  relativeTime: {
    future: "om %s",
    past: "%s siden",
    s: "noen sek",
    ss: "%d sek",
    m: "1 min",
    mm: "%d min",
    h: "1t",
    hh: "%dt",
    d: "1d",
    dd: "%dd",
    M: "1mnd",
    MM: "%dmnd",
    y: "1år",
    yy: "%dår"
  }
});


// print version
LicenseInfo.setLicenseKey("ed177109f2cbfbfea6219c5c1fa5b764Tz04NTc5MixFPTE3NDEyNjkxMzkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

// Set mapbox access token
mapboxgl.accessToken = "pk.eyJ1IjoibGVpZmVtYmVybHkiLCJhIjoiY2xtY2RpNG91MTZiMzNlbzVlYXVwZTdqayJ9.CzD4_lFvrYc-oXsS9mL6Ow";

// Event emitter for theme changes
const themeEventListener = new EventEmitter();
const themeMode = "light";

// React entry point
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter basename={config.basename}>
        <DeviceProvider>
          <ZenithAppContextProvider
            authorizationDomain="auth.getassist.app"
            authorizationClientId={config.auth.clientId}
            authorizationParams={{
              redirect_uri: window.location.origin,
              audience: "https://assist-service",
              scope: "openid profile email offline_access"
            }}
          >
            <ThemeConfig mode={themeMode} events={themeEventListener}>
              <SnackbarProvider autoHideDuration={3500} disableWindowBlurListener>
                <NotificationContextProvider vapidPublicKey={config.vapid.publicKey}>
                  <StationContextProvider>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <App />
                    </LocalizationProvider>
                  </StationContextProvider>
                </NotificationContextProvider>
              </SnackbarProvider>
            </ThemeConfig>
          </ZenithAppContextProvider>
        </DeviceProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: reg => store.dispatch({ type: UPDATE_AVAILABLE, payload: reg }),
  onSuccess: () => store.dispatch({ type: WORKER_INIT })
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
