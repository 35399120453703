import React, { useState, createContext, useContext, useEffect, useRef, useCallback, useMemo } from "react";
import { useEntity, useEntityField, Location, Compare, Defaults, useEntities, getValue, useAuth } from "@emberly/zenith-client";
import RouteCollection from "../common/RouteCollection";
import { useStation } from "./StationProvider";
import axios from "axios";
import { MakeFile, MakeStateChange, UploadMissionFile, MakeEmptyTask, MakeEmptyStorage, AssumeTaskState } from "../common/mission";
import { ActivityEnums, MissionEnums, MissionEnumsLists, OrderEnums, TaskEnums } from "../common/constants";
import { MakeNextOrderName } from "../common/orders";
import { MakeActivity } from "../common/activity";

export const MissionContext = createContext();
export const useMission = () => useContext(MissionContext);

export default function MissionContextProvider(props) {
  const { station, logEvent } = useStation();
  const { entity, loading, hasUpdate, updateEntityField, updateEntity, deleteEntity, id, pushToList, spliceList } = useEntity();
  const { getAccessTokenSilently, user } = useAuth();

  const [showFilesCard, setShowFilesCard] = useState(false);
  const [routeCollection, setRouteCollection] = useState(null);
  const [revision, setRevision] = useState(0);
  
  useEffect(() => {
    if (!!station && !!routeCollection) {
      routeCollection.setStationLocation(station?.includeDistanceFromStation && !!station.location ? new Location({ ...station.location, title: station.displayName }) : null)
    }
  }, [station, routeCollection]);

  useEffect(() => {
    const rc = new RouteCollection(station?.includeDistanceFromStation && !!station.location ? new Location({ ...station.location, title: station.displayName }) : null);
    setRouteCollection(rc);
    return () => {
      setRouteCollection(null);
      rc?.destroy();
    };
  }, [id, station]);

  const addServiceInfo = useCallback(() => {
    updateEntityField(
      "serviceInfo",
      {
        serviceCenter: "",
        milageAtService: 0,
        currentMilage: 0,
        validWarranty: false
      }
    );
  }, [updateEntityField]);

  const removeServiceInfo = useCallback(() => {
    updateEntityField(
      "serviceInfo", null
    );
  }, [updateEntityField]);

  const [uploadingFiles, setUploadingFiles] = useState([]);

  const uploadFiles = useCallback((files, progressFn = () => { }) => {
    const fileRequests = files.map(t => MakeFile(t));

    const worker = async () => {
      const res = await axios.post(
        `/api/mission/${id}/file/request`,
        fileRequests,
        {
          headers: { Authorization: `Bearer ${await getAccessTokenSilently()}` },
        }
      );

      const policies = res.data;

      const tasks = fileRequests.map((_, i) => {
        return (async () => {
          const policy = policies[i];
          const file = files[i];

          await UploadMissionFile(file, policy, p => progressFn(policy.file, p));
          await updateEntityField(`files.uploads.${policy.file.id}.complete`, true);
          setRevision(r => r + 1);
        })();
      });

      await Promise.all(tasks);
    }

    worker();

    return fileRequests;

  }, [getAccessTokenSilently, id, updateEntityField]);

  const deleteFile = useCallback(async (fileId) => {
    await axios.delete(
      `/api/mission/${id}/file/${fileId}`,
      {
        headers: { Authorization: `Bearer ${await getAccessTokenSilently()}` },
      }
    );
  }, [getAccessTokenSilently, id]);

  const hasFiles = !!entity?.files?.uploads ? Object.keys(entity.files.uploads).length > 0 : false;

  const onSelectFiles = useCallback((ev) => {
    setUploadingFiles(
      uploadFiles([...ev.target.files], (file, progress) => {
        setUploadingFiles(files => files.map(
          f => file.id === f.id ? { ...f, ...progress } : f
        ));
      })
    );
  }, [uploadFiles]);


  const taskList = useMemo(() => {
    const indexedTasks = entity.salvageTasks.map((t, index) => ({ ...t, index }));
    return (!!entity.storageTask ? indexedTasks.concat([entity.storageTask]).sort((a, b) => a.number - b.number) : indexedTasks)
      .map(t => ({ ...t, completed: t.taskType === TaskEnums.Type.Storage ? t.delivered : AssumeTaskState(t) >= TaskEnums.State.Completed }));
  }, [entity, entity?.salvageTasks, entity.storageTask]);


  const makeSalvageTask = useCallback(async (taskName = null) => {
    const task = MakeEmptyTask(1 + Math.max(0, ...taskList.map(t => t.number)), typeof taskName === "string" ? taskName : null);

    if (entity.state >= MissionEnums.State.Created) {
      task.execution.history.created = MakeStateChange(user);
    }

    await pushToList("salvageTasks", task);
    return task;
  }, [pushToList, taskList, entity, user]);

  const makeStorageTask = useCallback(async () => {
    const task = MakeEmptyStorage(1 + Math.max(0, ...taskList.map(t => t.number)))
    await updateEntityField("storageTask", task);
    return task;
  }, [updateEntityField, taskList]);


  const { entities: orders, createEntity: createOrder, deleteEntity: deleteOrder } = useEntities("Order", useMemo(
    () => ({
      path: "missionId",
      value: id,
      comparer: Compare.EQ,
      name: `mission_orders_${id}`
    }),
    [id])
  );

  const makeOrder = useCallback(async (paymentData = {}) => {
    return await createOrder({ 
      missionId: id,
      missionNumber: entity?.number || 0,
      name: MakeNextOrderName(orders),
      payment: {
        calculatedTotal: {
          value: "",
          currency: OrderEnums.Currency.NOK,
        },
        sender: {
          id: user.sub,
          name: user.name
        },
        ...paymentData
      } 
    });
  }, [createOrder, id, user, orders, entity]);  

  const logMissionEvent = useCallback((type, value = "", description = "") => {
    logEvent(MakeActivity(ActivityEnums.Category.Mission, type, id, value, description));
  }, [logEvent, id]);

  return (
    <MissionContext.Provider
      value={{
        id,
        taskList,
        hasUpdate,
        makeStorageTask,
        makeSalvageTask,
        updateEntity,
        loading,
        showServiceInfo: !!entity.serviceInfo,
        addServiceInfo,
        removeServiceInfo,
        uploadFiles,
        deleteFile,
        showFilesCard: showFilesCard || hasFiles,
        hasFiles,
        setShowFilesCard,
        mission: entity,
        deleteMission: deleteEntity,
        updateMissionField: updateEntityField,
        routeCollection,
        pushToList,
        spliceList,
        revision,
        uploadingFiles,
        setUploadingFiles,
        onSelectFiles,
        orders,
        deleteOrder,
        makeOrder,
        logMissionEvent,
      }}
    >
      {!!entity ? props.children : null}
    </MissionContext.Provider>
  );
}


export function useMissionRoute(path, load = true) {
  const { onChange, value: list } = useEntityField(`${path}.route.waypoints`, Defaults.List, load);
  const { routeCollection, mission, updateMissionField } = useMission();
  
  const missionRef = useRef(mission);
  missionRef.current = mission;
  
  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;
  
  const routeRevisionRef = useRef(0);
  const [_, setRouteRevision] = useState(routeRevisionRef.current);

  useEffect(() => {
    if (load && !!routeCollection) {
      const route = routeCollection.getRoute(path);

      const updateRoute = (origin) => {
        if (origin !== "updateFromRawList") {
          
          onChangeRef.current(null, route.locations.map(t => t.getData()));
          
          if (route.metadata.distanceInRouteKM === -1 && route.metadata.distanceToRouteKM === -1 && route.isEmpty) {
            updateMissionField(`${path}.route.distanceToRouteKM`, 0);
            updateMissionField(`${path}.route.distanceInRouteKM`, 0);
          }
        }

        setRouteRevision(() => ++routeRevisionRef.current);
      };

      const updateMetadata = (metadata) => {
        // TODO update metadata from route, distances
        const routeData = getValue(missionRef.current, `${path}.route`);
        
        if (metadata.distanceToRouteKM !== -1 && metadata.distanceToRouteKM !== routeData.distanceToRouteKM) {
          updateMissionField(`${path}.route.distanceToRouteKM`, metadata.distanceToRouteKM);
        } else if (metadata.distanceToRouteKM === -1 && routeData.distanceToRouteKM !== 0) {
          updateMissionField(`${path}.route.distanceToRouteKM`, 0);
        }

        if (metadata.distanceInRouteKM !== -1 && metadata.distanceInRouteKM !== routeData.distanceInRouteKM) {
          updateMissionField(`${path}.route.distanceInRouteKM`, metadata.distanceInRouteKM);
        } else if (metadata.distanceInRouteKM === -1 && routeData.distanceInRouteKM !== 0) {
          updateMissionField(`${path}.route.distanceInRouteKM`, 0);
        }
      };

      route.on("update", updateRoute);
      route.on("metadata", updateMetadata);

      return () => {
        route.off("update", updateRoute);
        route.off("metadata", updateMetadata);
      };
    }
  }, [routeCollection, load, path, updateMissionField]);


  // update when list changes
  useMemo(() => {
    if (load && list !== undefined && list !== null && !!routeCollection) {
      const route = routeCollection.getRoute(path);
      // update route
      if (list.length === 0) {
        if (!route.isEmpty) {
          route.updateFromRawList([{}]);
        }
      } else {
        route.updateFromRawList(list);
      }
    }
  }, [list, routeCollection, load, path]);

  // Reorder method
  const reorder = useCallback((result) => {
    if (result.combine || !result.destination || result.destination.index === result.source.index) {
      return;
    }

    const route = routeCollection.getRoute(path);

    route.reorder(
      result.source.index,
      result.destination.index,
    );
  }, [routeCollection, path]);

  return {
    route: routeCollection?.getRoute(path), routeRevision: routeRevisionRef.current, reorder, onChange
  };
}



export function getMissionTargetDescription(mission) {
  if (!mission) return "";
  
  const target = mission.target;
  const targetType = target.type;
  const targetDetails = targetType === 1 ? target.carDetails : (targetType === 2 ? target.boatDetails : null);

  return  `${!!targetDetails ? `${targetDetails.make || "-"} ${targetDetails.model || "-"}, ${targetDetails.registration || "-"} ` : ""}`;
}

export function getMissionJobDescription(t, mission) {
  if (!mission) return "";

  const jobType = t(`mission:enums:type:${MissionEnumsLists.Type[mission.details.type || 0]}`);
  const targetTypeName = t(`mission:enums:targetType:${MissionEnumsLists.TargetType[mission.target?.type || 0]}`);
  
  return `${jobType} ${targetTypeName}`;
}

export function getMissionCauseDescription(t, mission) {
  if (!mission) return "";

  const jobType = t(`mission:enums:type:${MissionEnumsLists.Type[mission.details.type || 0]}`);
  const cause = mission.details?.cause?.name || jobType;
  const tags = mission.details?.tags?.map(t => `\n[${t.name}]`)?.join("");
  
  return `${!!cause ? `${cause} ` : ""}${tags}`;
}