import { useTranslation } from "react-i18next";
import { useInvoiceOrderExport } from "../../../../../hooks/useInvoiceExport";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import MultilineButton from "../../../inputs/MultilineButton";
import InvoiceExportDialog from "../../dialogs/InvoiceExportDialog";

export default function JournalizeInvoiceButton(props) {
  const { setWizard } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { loading, working, exportAction, dialogAction, exportOrder, hasExportSettings, exportResult, } = useInvoiceOrderExport();

  const onExportOrder = useCallback(async () => {
    setWizard(true);
    const { success, exportId, openResultPage } = await exportOrder();
    if (!!success && !!openResultPage) {
      navigate(`/invoicing/journals/${exportId}`, { relative: "path" });
    } else {
      setWizard(false);
    }
  }, [exportOrder, navigate]);

  return (
    <>
      <MultilineButton fullWidth variant="contained" color="primary" onClick={onExportOrder} disabled={loading || working || !hasExportSettings}>
        {t("order:invoiceFlow:export")}
      </MultilineButton>

      {
        !!dialogAction ? (
          <InvoiceExportDialog
            exportAction={exportAction}
            dialogAction={dialogAction}
            exportResult={exportResult}
            working={working}
          />
        ) : null
      }
    </>
  );
}
