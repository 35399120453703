import { makeStyles } from "tss-react/mui";
import { Grid, Menu, Divider, MenuItem, Typography, Button, Tabs, Tab } from "@mui/material";
import { ToggleSurface } from "../../../../common/core/Surface";
import { useTranslation } from "react-i18next";
import { useCallback, useState, useMemo } from "react";
import { useMission, useMissionRoute } from "../../../../../providers/MissionProvider";
import TaskMapSection from "../../../../common/map/TaskMapSection";
import SalvageTaskDetails from "./SalvageTaskDetails";
import StorageTaskDetails from "./StorageTaskDetails";
import TextDialog from "../../../../common/inputs/TextDialog";
import { Task } from "@emberly/zenith-client";
import { CheckCircle as CheckIcon, PendingSharp as PendingIcon } from "@mui/icons-material";
import { useParams, useNavigate } from "react-router-dom";

const useStyles = makeStyles()(
  (theme) => ({
    tabs: {
      marginBottom: theme.spacing(1),
      borderBottom: `1px solid ${theme.palette.divider}`,
      minHeight: "44px",
      height: "44px",
    },
    tab: {
      minHeight: "44px",
      height: "44px",
      "& svg": {
        height: "18px",
        width: "18px"
      }
    },
    gridItem: {
      position: "relative",
      minHeight: "100%"
    }
  })
);

export default function TaskCard() {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { taskNumber } = useParams();
  const navigate = useNavigate();
  const { mission, taskList, makeSalvageTask, makeStorageTask } = useMission();
  const hasStorage = !!mission.storageTask;
  const canDelete = taskList.length > 1;

  const { tabPath, taskNumberOrDefault, taskNotFound , selectedTask} = useMemo(() => {
    const taskNumberOrDefault = Number(taskNumber || taskList[0]?.number || -1);
    const selectedTask = taskList.find(t => t.number === taskNumberOrDefault);
    return {
      selectedTask,
      tabPath: !!selectedTask ? taskList.length === 0 ? "" : selectedTask.taskType === 2 ? "storageTask" : `salvageTasks.${selectedTask.index}` : null,
      taskNumberOrDefault,
      taskNotFound: !selectedTask
    };
  }, [taskNumber, taskList]);

  const onTabChange = useCallback((_, path) => navigate(!!taskNumber ? `../${path}` : `./${path}`, { relative: "path", replace: true }), [navigate, taskNumber]);
  const storageSelected = tabPath === "storageTask";
  const { route, routeRevision, reorder, onChange: onChangeRoute } = useMissionRoute(tabPath, !storageSelected); // TODO how to do this nicely with storage task selected

  //console.log(tabPath, route.locations.length);
  // menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClose = useCallback(() => setAnchorEl(null), []);
  const handleOpen = useCallback(ev => setAnchorEl(ev.currentTarget), []);

  const onMakeSalvageTask = useCallback(async (taskName = null) => {
    handleClose();
    const task = await makeSalvageTask(taskName);
    onTabChange(null, task.number);
  }, [makeSalvageTask, handleClose, onTabChange]);

  const onMakeStorageTask = useCallback(async () => {
    handleClose();
    const task = await makeStorageTask();
    onTabChange(null, task.number);
  }, [makeStorageTask, handleClose, onTabChange]);

  const [makeNamedTaskAction, setMakeNamedTaskAction] = useState(null);

  const onMakeNamedTask = useCallback(async () => {
    handleClose();
    try {
      const task = new Task();
      setMakeNamedTaskAction(task);

      const taskName = await task.wait();
      await onMakeSalvageTask(taskName);

    } catch (err) {
      console.log(err);
    } finally {
      setMakeNamedTaskAction(null);
    }
  }, [onMakeSalvageTask, handleClose]);

  const onDeleted = useCallback((taskId) => {
    const idx = taskList.findIndex(t => t.taskId === taskId);
    const mutatedList = taskList.filter(t => t.taskId !== taskId);
    const newIndex = Math.max(0, Math.min(mutatedList.length - 1, idx - 1));
    const newElement = mutatedList[newIndex];
    onTabChange(null, newElement.number);
  }, [storageSelected, taskList, tabPath, onTabChange]);

  const noTasks = taskList.length === 0;

  const getTabLabel = useCallback((task) => {
    return task.taskName || t(`mission:cards:tasks:${task.taskType === 2 ? "storageTask" : "salvageTask"}:defaultName`);
  }, [t]);

  return (
    <>
      <ToggleSurface xs={12} collapse={noTasks}>
        <Grid item container spacing={2} alignItems="stretch">

          <Grid item container xs={12} lg={6}>
            <Grid container spacing={1} direction="row" alignItems="flex-start" justifyContent="flex-start">

              <Grid item xs>
                <Typography variant="h5">{t("mission:cards:tasks:title")}</Typography>
              </Grid>

              <Grid item>
                <Button onClick={handleOpen}>{t("mission:cards:tasks:create")}</Button>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem onClick={onMakeSalvageTask}>{t("mission:cards:tasks:menu:createAssistance")}</MenuItem>
                  <MenuItem onClick={onMakeNamedTask}>{t("mission:cards:tasks:menu:createNamedTask")}</MenuItem>
                  <Divider />
                  <MenuItem onClick={onMakeStorageTask} disabled={hasStorage}>{t("mission:cards:tasks:menu:createStorage")}</MenuItem>
                </Menu>
              </Grid>

              <Grid item xs={12}>
                <Tabs value={taskNotFound ? "error" : taskNumberOrDefault} onChange={onTabChange} className={classes.tabs}>
                  {taskList.map((t, i) => (
                    <Tab
                      className={classes.tab}
                      value={t.number}
                      icon={t.completed ? <CheckIcon /> : <PendingIcon />}
                      iconPosition="start"
                      label={getTabLabel(t)}
                      key={i}
                      size="small"
                    />
                  ))}
                  {taskNotFound ? (
                    <Tab
                      className={classes.tab}
                      value={"error"}
                      icon={<PendingIcon />}
                      iconPosition="start"
                      label="Task Not Found"
                      size="small"
                    />
                  ) : null}
                </Tabs>
              </Grid>

              <Grid item xs={12}>
                {taskNotFound ? (
                  null
                ) : storageSelected ? (
                  <StorageTaskDetails
                    path={tabPath}
                    onDeleted={onDeleted}
                    canDelete={canDelete}
                  />
                ) : (
                  <SalvageTaskDetails
                    path={tabPath}
                    route={route}
                    routeRevision={routeRevision}
                    reorder={reorder}
                    onChangeRoute={onChangeRoute}
                    onDeleted={onDeleted}
                    canDelete={canDelete}
                  />
                )
                }
              </Grid>

            </Grid>
          </Grid>

          <Grid className={classes.gridItem} item container xs={12} lg={6}>
            <TaskMapSection
              route={storageSelected ? null : route}
              routeRevision={routeRevision}
              location={storageSelected ? mission.storageTask?.warehouse?.location : null}
              path={tabPath}
              vehicleId={selectedTask?.execution?.vehicle?.id}
              details
            />
          </Grid>

        </Grid>

        <TextDialog
          label={t("mission:cards:tasks:createNamedTaskDialog:label")}
          title={t("mission:cards:tasks:createNamedTaskDialog:title")}
          description={t("mission:cards:tasks:createNamedTaskDialog:description")}
          confirmText={t("mission:cards:tasks:createNamedTaskDialog:confirmText")}
          task={makeNamedTaskAction}
        />
      </ToggleSurface>
    </>
  );
}

// 