import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";
import ResponsiveSubPage from "../../common/core/ResponsiveSubPage";
import { useMissionTask } from "../../../providers/MissionTaskProvider";
import { MissionEnums, MissionEnumsLists, TaskEnums } from "../../../common/constants";
import { GetTaskDriverState, GetTaskDriverStateColor } from "../../../common/mission";
import { Grid, Chip, Typography, Alert, AlertTitle } from "@mui/material";
import moment from "moment/moment";
import TaskMapSection from "../../common/map/TaskMapSection";
import { useMissionRoute } from "../../../providers/MissionProvider";
import MissionTaskDetailsCard from "./cards/MissionTaskDetailsCard";
import MissionTaskSectionsCard from "./cards/MissionTaskSectionsCard";
import { useDevice } from "../../../providers/DeviceProvider";
import { ToggleSurface } from "../../common/core/Surface";
import NotFoundPage from "../error/NotFoundPage";

const useStyles = makeStyles()(
  (theme) => ({
    mapSection: {
      height: "100%",
      borderRadius: `${theme.spacing(2)} !important`,
      "& .map": {
        borderRadius: `${theme.spacing(2)} !important`,
      }
    },
    mapSurface: {
      height: "100%",
      position: "relative"
    },
    mapCollapse: {
      "& .MuiCollapse-wrapper": {
        height: "100%"
      }
    }
  })
);

export default function MissionTaskPage() {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const { mission, missionTask, taskState, taskPath, lastTaskState, continueMissionText, driverAssignedState, continueMission, rejectTask, notFound } = useMissionTask();
  const missionDetails = mission.details;
  const { route, routeRevision } = useMissionRoute(taskPath); // TODO how to do this nicely with storage task selected
  const { slim } = useDevice();

  return notFound ? (
    <NotFoundPage backPath="../../" />
  ) : (
    <ResponsiveSubPage
      title={`${t(`mission:enums:type:${MissionEnumsLists.Type[missionDetails.type]}`)} #${mission?.number || "-"}.${missionTask?.number || "-"}`}
      backPath="../../"
      backButton
      primaryAction={driverAssignedState === TaskEnums.DriverAssignedState.Pending ? t("task:common:reject") : undefined}
      onPrimaryAction={driverAssignedState === TaskEnums.DriverAssignedState.Pending ? rejectTask : undefined}
      secondaryAction={continueMissionText}
      onSecondaryAction={continueMission}
    >
      <Grid item container xs={12} justifyContent="space-between" alignItems="baseline" spacing={1}>
        {
          mission?.state === MissionEnums.State.Cancelled ? (
            <Grid item xs={12}>
              <Alert variant="filled" severity="error">
                <AlertTitle>{t("task:common:cancelledTitle")}</AlertTitle>
                {t("task:common:cancelledDescription")}
              </Alert>
            </Grid>
          ) : null
        }
        <Grid item>
          <Chip color={GetTaskDriverStateColor(driverAssignedState, taskState, false)} label={GetTaskDriverState(driverAssignedState, taskState, t)} />
        </Grid>
        {
          !!lastTaskState ? (
            <Grid item>
              <Typography variant="body2" color="textSecondary">{moment(lastTaskState.lastModified).fromNow()}</Typography>
            </Grid>
          ) : null
        }
      </Grid>

      <Grid item container xs={12} justifyContent="stretch" spacing={3}>

        {!slim || taskState < TaskEnums.State.Arrived ? (
          <ToggleSurface xs={12} lg={6} className={classes.mapSurface} outerClassName={classes.mapCollapse}>

            <TaskMapSection
              className={classes.mapSection}
              routeRevision={routeRevision}
              route={route}
              path={taskPath}
              fitScreenButton
              vehicleId={missionTask?.execution?.vehicle?.id}
            />

          </ToggleSurface>
        ) : null}

        <Grid item container xs={12} lg={6} spacing={3}>
          <MissionTaskDetailsCard />
          {taskState >= TaskEnums.State.Arrived ? (
            <MissionTaskSectionsCard />
          ) : null}
        </Grid>

      </Grid>


    </ResponsiveSubPage>
  );
}
