import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    // TODO translate by gpt4
  },
  no: {
    sidebar: {
      myPagesCategory: "Mine sider",
      missionsCategory: "Oppdrag",
      accountingCategory: "Regnskap",
      registersCategory: "Administrasjon",
      settingsCategory: "Innstillinger",
      station: "Stasjon",
      integrations: "Integrasjoner",
      home: "Oversikt",
      activityLog: "Aktivitet",
      myMissions: "Mine oppdrag",
      myCashSettlement: "Mitt kasseoppgjør",
      drivers: "Sjåføroversikt",
      drafts: "Utkast",
      dispatch: "Aktive tjenester",
      allMissions: "Alle oppdrag",
      allTasks: "Alle tjenester",
      activeMissions: "Åpne oppdrag",
      cause: "Skadeårsak",
      serviceVehicle: "Servicekjøretøy",
      missionTag: "Oppdragsdetaljer",
      contact: "Kontakter",
      product: "Produkter",
      agreement: "Avtaler",
      warehouse: "Lagerlokasjoner",
      storage: "Lager",
      orders: "Ordre",
      invoicing: "Faktura",
      cashSettlements: "Kasseoppgjør",
      cashOrders: "Kontantordre",
      vatCodes: "MVA-koder",
      productGroups: "Produktgrupper",
      exportSettings: "Journal",
      bottomCard: {
        showLog: "Vis aktivitetslogg",
        accountSettings: "Kontoinstillinger",
        switchStation: "Bytt stasjon",
        logoutDialog: {
          title: "Bekreft utlogging",
          description: "Er du sikker du ønsker å logge ut?",
          action: "Logg ut",
        },
        switchStationDialog: {
          title: "Endre aktiv stasjon",
          action: "Bytt stasjon",
          selectLabel: "Aktiv stasjon"
        }
      },
      accountSettings: {
        title: "Kontoinnstillinger",
        name: "Navn",
        email: "E-post",
        emailHelperText: "Kontakt administrator for å endre epost",
        phoneNumber: "Telefonnummer",
        avatar: {
          edit: "Endre profilbilde",
          ok: "Ferdig",
          cancel: "Avbryt",
          upload: "Klikk eller dra for å velge"
        }
      }
    },
    common: {
      location: {
        pickerTitle: "Velg lokasjon fra kartet",
        pickerDescription: "Velg posisjon på kartet, og så trykk på 'Velg' for å hente addresse.",
        pickerButton: "Velg lokasjon"
      },
      today: "i dag",
      yesterday: "i går",
      confirm: "Bruk",
      ok: "Ok",
      cancel: "Avbryt",
      save: "Lagre endringer",
      contact: "Kontakt",
      agreement: "Avtale",
      noResults: "Ingen resultater",
      searchHint: "Søk eller skriv '*' for å liste ut alle",
      resetTable: "Nullstill visning",
      exported: "Eksportert",
      notExported: "Ikke eksportert",
      stateUpdated: "Status oppdatert til",
      tables: {
        id: "ID",
        created: "Opprettet",
        state: "Status",
        type: "Oppdragstype",
        cause: "Skadeårsak",
        tags: "Tags",
        object: "Objekt",
        place: "Skadested",
        agreement: "Avtale",
        address: "Skadeadresse",
        transportTo: "Transport til",
        locationDescription: "Beskrivelse oppdragssted",
        details: "Detaljer",
        driver: "Sjåfør",
        missionNumber: "Oppdragsnr.",
        number: "Nummer",
        noDriver: "Ingen sjåfør",
        registration: "Reg.nr",
        eta: "ETA",
        make: "Merke",
        model: "Modell",
        serviceVehicle: "Servicekjøretøy"
      }
    },
    notification: {
      requestAccessText: "Assist vil gjerne ha tilgang til å sende deg push varsler om viktige hendelser",
      requestAccessAction: "Gi tilgang",
      driverAssignedNotificationTitle: "Oppdatering fra dispatch",
      driverAssignedNotificationDescription: "Du har blitt tildelt et nytt oppdrag",
      cancelAction: "Lukk",
      okAction: "Ok",
      openAction: "Åpne"
    },
    activityLogPane: {
      title: "Aktivitetslogg"
    },
    activity: {
      enums: {
        category: {
          None: "Ukjent",
          Mission: "Oppdrag",
          Billing: "Regnskap",
          Other: "Annet"
        },
        type: {
          None: "Ingen type",
          Text: "Fritekst oppdatering",
          ReportState: "Status rapportert",
          AssignedTask: "Delegert tjeneste",
          AcceptedTask: "Akseptert tjeneste",
          RejectedTask: "Avslått tjeneste",
          TaskStarted: "Er på vei",
          TaskArrived: "Ankommet tjenestested",
          TaskLoaded: "Opplastet",
          TaskCompleted: "Tjeneste utført",
          TaskAcknowledged: "Kvittert tjeneste",
          MissionDraftCreated: "Laget nytt oppdragsutkast",
          MissionCreated: "Oppdrag opprettett",
          MissionCompleted: "Oppdrag fullført",
          MissionCancelled: "Oppdrag avbestilt",
          MissionDeleted: "Oppdrag slettet",
          StoredObjectDelivered: "Lagret objekt utlevert",

          MissionPlaceholder2: "",
          MissionPlaceholder3: "",
          MissionPlaceholder4: "",
          MissionPlaceholder5: "",
          MissionPlaceholder6: "",
          MissionPlaceholder7: "",
          MissionPlaceholder8: "",
          MissionPlaceholder9: "",
          MissionPlaceholder10: "",
          MissionPlaceholder11: "",
          MissionPlaceholder12: "",
          MissionPlaceholder13: "",
          MissionPlaceholder14: "",
          MissionPlaceholder15: "",
          MissionPlaceholder16: "",
          MissionPlaceholder17: "",
          MissionPlaceholder18: "",
          MissionPlaceholder19: "",
          MissionPlaceholder20: "",

          InvoiceJournalCreated: "Fakturajournal opprettet",
          CashJournalCreated: "Kontantjournal opprettet",
          InvoiceQueued: "Faktura merket som klar for journalføring",
          InvoiceReOpened: "Faktura gjenåpnet for redigering",
          PaymentRequested: "Betaling forespurt",
          PaymentConfirmed: "Betaling bekreftet",

          OrdersSettled: "Kasseoppgjør utført",
          SettlementsApproved: "Flere kasseoppgjør godkjent",
          SettlementApproved: "Kasseoppgjør godkjent",

        }
      }
    },
    entityTable: {
      add: "Legg til",
      edit: "Lagre",
      delete: "Slett",
      cancel: "Avbryt",
      search: "Søk"
    },
    home: {
      title: "Oversikt",
      createMission: "Nytt oppdrag",
      updateAvailable: "Ny versjon tilgjengelig",
      cards: {
        activeServices: {
          title: "Aktive tjenester",
          linkText: "Tjenester"
        },
        openMissions: {
          title: "Åpne oppdrag",
          linkText: "Oppdrag"
        },
        inStorage: {
          title: "På lager",
          linkText: "Lager"
        },
        openOrders: {
          title: "Åpne ordre",
          linkText: "Ordre"
        },
        map: {
          title: "Steder",
          target: "Objekt",
          driver: "Sjåfør",
          state: "Status"
        }
      }
    },
    statistics: {
      title: "Statistikk",
      cards: {
        map: {
          title: "Oppdragssteder",
        }
      }
    },
    notFound: {
      title: "404 - Ikke funnet",
      subHeader: "Denne siden eksisterer ikke",
      description: "Trykk på knappen under for å gå tilbake til hjemmeskjermen.",
      button: "Tilbake"
    },
    offline: {
      title: "Ingen tilkobling",
      description: "Vennligst gjenopprett internettforbindelsen for å bruke Assist"
    },
    connecting: {
      title: "Kobler til Assist",
      description: "Vennligst vent mens vi kobler oss opp mot Assist"
    },
    activityLog: {
      title: "Aktivitet",
      emptyStateTitle: "Ingen aktivitet",
      emptyStateDescription: "Her dukker hendelser opp når det skjer aktivitet på systemet",
      fieldCategory: "Kategori",
      fieldType: "Type",
      fieldSender: "Person"
    },
    drafts: {
      title: "Utkast",
      createMission: "Nytt oppdrag",
      emptyStateTitle: "Ingen oppdragsutkast",
      emptyStateDescription: "Her dukker utkast for oppdrag opp",
      table: {
        mission: "Oppdrag"
      }
    },
    myMissions: {
      title: "Mine oppdrag",
      activeMissionsTab: "Aktiv",
      completedMissionsTab: "Utført",
      emptyStateTitle: "Ingen aktive oppdrag",
      emptyStateDescription: "Så snart du blir tildelt et oppdrag, vil det vises her.",
      completedEmptyStateTitle: "Ingenting er markert utført",
      completedEmptyStateDescription: "Dette er hvor utførte tjenester vil vises for deg til å gjennomgå og kvittere.",
      vehicleSelector: {
        title: "Kjøretøy",
        none: "Ingen kjøretøy",
        vehicleInUseTitle: "Kjøretøyet er allerede i bruk av en annen sjåfør",
        vehicleInUseDescription: "Trykk “Bruk” for å sette over dette kjøretøyet til deg selv"
      },
      driverActiveStateSelector: {
        working: "På jobb",
        notWorking: "Fri"
      }
    },
    taskDetailsCard: {
      created: "Oppdrag opprettet",
      eta: "Estimert ankomst",
      type: "Oppdragstype",
      details: "Oppdragsdetaljer",
      comment: "Kommentar",
      target: "Objekt",
      location: "Oppdragssted",
      locationDescription: "Beskrivelse oppdragssted",
      transport: "Transport til",
      requester: "Rekvirent",
      requesterPhoneNumber: "Rekvirent tlf.",
      callRequester: "Ring rekvirent",
      contactPerson: "Kontaktperson",
      contactPersonPhoneNumber: "Kontaktperson tlf.",
      callContactPerson: "Ring kontaktperson",
      customerWaiting: "Kunde venter",
      keyPlacement: "Nøkkelplassering",
      showMore: "Vis all info"
    },
    taskSectionsCard: {
      documentation: "Dokumentasjon",
      pluralFiles: "filer lastet opp",
      singleFile: "En fil lastet opp",
      noFiles: "Ingen filer lastet opp",
      assistance: "Assistanse",
      payment: "Betaling",
      orders: "ordre",
      serviceInfo: "Serviceinfo",
      serviceInfoEmpty: "Serviceinfo er tom",
      serviceInfoNotAdded: "Serviceinfo ikke lagt til",
      assistanceLocationNotAdded: "Oppdragssted ikke lagt til",
      storage: "Lagring",
      storageDescription: "Lagret på",
      storageNotAdded: "Lagring ikke lagt til",
      storageInfoMissing: "Lagring lagt til, informasjon mangler"
    },
    taskFilesPage: {
      title: "Dokumentasjon",
      upload: "Last opp"
    },
    taskServiceInfoPage: {
      title: "Serviceinfo",
      delete: "Fjern serviceinfo",
      contract: "Avtale",
      insurer: "Forsikringsselskap",
      emptyState: {
        title: "Ingen serviceinfo lagt til",
        description: "Trykk “Legg til serviceinfo” for å opprette skjema for serviceinfo",
        buttonText: "Legg til serviceinfo"
      }
    },
    taskStoragePage: {
      title: "Lagring",
      delete: "Fjern lagring",
      emptyState: {
        title: "Ingen lagring lagt til",
        description: "Trykk “Legg til lagring” for å opprette skjema for lagring",
        buttonText: "Legg til lagring"
      }
    },
    taskOrdersPage: {
      title: "Betaling",
      add: "Ny ordre",
      order: "Ordre",
      deductible: "egenandel",
      products: "produkter",
      product: "Ett produkt",
      and: "og",
      emptyStateTitle: "Her er det tomt",
      emptyStateDescription: "Trykk “+ Ny ordre” for å legge til betalinger og ordre"
    },
    taskOrderPage: {
      title: "Ordre",
      delete: "Fjern ordre",
      openMission: "Åpne oppdrag",
      number: "Ordrenr."
    },
    taskAssistancePage: {
      title: "Assistanse"
    },
    activeMissions: {
      title: "Åpne oppdrag",
      createMission: "Nytt oppdrag",
      emptyStateTitle: "Ingen aktive oppdrag",
      emptyStateDescription: "Her dukker aktive oppdrag opp",
      table: {
        mission: "Oppdrag",
        agreement: "Avtale",
        taskState: "Tjenestestatus",
        tasksCompleted: "Utført",
        tasksNotCompleted: "Pågår",
        storageState: "Lagerstatus",
        storageCompleted: "Utlevert",
        storageNotCompleted: "På lager",
        noStorage: "Ingen lagring",
        invoiceState: "Ordrestatus",
        invoiceCompleted: "Betalt",
        invoiceNotCompleted: "Åpen",
        invoiceValue: "Beløp",
        deductible: "Egenandel",
        orderState: "Ordrestatus",
        orderCount: "Antall ordre",
      }
    },
    missionsArchive: {
      title: "Alle oppdrag",
      createMission: "Nytt oppdrag",
      emptyStateTitle: "Ingen oppdrag",
      emptyStateDescription: "Her dukker oppdrag opp",
      table: {
        mission: "Oppdrag",
        requester: "Rekvirent",
        taskState: "Tjenestestatus",
        tasksCompleted: "Utført",
        tasksNotCompleted: "Pågår",
        storageState: "Lagerstatus",
        storageCompleted: "Utlevert",
        storageNotCompleted: "På lager",
        noStorage: "Ingen lagring",
        invoiceState: "Ordrestatus",
        invoiceCompleted: "Betalt",
        invoiceNotCompleted: "Åpen",
        invoiceValue: "Beløp",
        deductible: "Egenandel"
      }
    },
    tasksArchive: {
      title: "Alle tjenester",
      createMission: "Nytt oppdrag",
      emptyStateTitle: "Ingen tjenester",
      emptyStateDescription: "Her dukker arkiverte oppdrag opp",
      table: {
        number: "Tjenestenr.",
        missionCreated: "Oppdrag opprettet",
        driver: "Sjåfør",
      }
    },
    dispatch: {
      title: "Aktive tjenester",
      createMission: "Nytt oppdrag",
      table: {
        missionNumber: "Oppdrag",
        taskNumber: "Tjenestenr.",
        resolvedAtLocation: "Klar på plass"
      },
      activeTasks: {
        title: "Pågår",
        emptyStateTitle: "Ingen aktive tjenester",
        emptyStateDescription: "Her dukker aktive tjenester opp"
      },
      completedTasks: {
        title: "Utført i dag",
        emptyStateTitle: "Ingen utførte tjenester",
        emptyStateDescription: "Her dukker utførte tjenester opp"
      }
    },
    orders: {
      title: "Ordre",
      openOrdersTab: "Åpne ordre",
      allOrdersTab: "Alle ordre"
    },
    openOrders: {
      emptyStateTitle: "Ingen åpne ordre",
      emptyStateDescription: "Her dukker åpne ordre opp"
    },
    allOrders: {
      emptyStateTitle: "Ingen ordre",
      emptyStateDescription: "Her dukker ordre opp"
    },
    cashSettlement: {
      title: "Kasseoppgjør",
      orders: "Ordre i kasseoppgjør",
      ordersDescription: "Kasseoppgjøret består av",
      order: "ordre"
    },
    cashSettlements: {
      title: "Kasseoppgjør",
      approvalTab: "Til godkjenning",
      archiveTab: "Arkiv"
    },
    cashSettlementsForApproval: {
      fieldNumber: "Nummer",
      fieldSettledDate: "Dato oppgjort",
      fieldSender: "Signert av",
      fieldSumCash: "Sum kontant",
      fieldSumCard: "Sum kort",
      fieldSumApp: "Sum app",
      fieldSumTotal: "Totalt",
      fieldApprove: "Godkjenn",
      emptyStateTitle: "Ingen oppgjør til godkjenning",
      emptyStateDescription: "Kasseoppgjør dukker opp her etter at de har blitt oppgjort",
      approveAllTitle: "Godkjenn kasseoppgjør",
      approveAllDescriptionPart1: "Du har",
      approveAllDescriptionPart2: "kasseoppgjør på totalt",
      approveAllDescriptionPart3: "som må godkjennes før ordrene kan journalføres.",
      approveAllButton: "Godkjenn alt",
      approveAllDialog: {
        title: "Bekreft godkjenning",
        description: "Du vil nå godkjenne flere kasseoppgjør for journalføring. Denne handlingen kan ikke angres.",
        confirmText: "Godkjenn flere kasseoppgjør"
      }
    },
    approvedCashSettlements: {
      fieldNumber: "Nummer",
      fieldSettledDate: "Dato oppgjort",
      fieldSender: "Signert av",
      fieldApprovedDate: "Dato godkjent",
      fieldApprovedBy: "Godkjent av",
      fieldSumCash: "Sum kontant",
      fieldSumCard: "Sum kort",
      fieldSumApp: "Sum app",
      fieldSumTotal: "Totalt",
      fieldApprove: "Godkjenn",
      emptyStateTitle: "Ingen oppgjør",
      emptyStateDescription: "Kasseoppgjør dukker opp her etter at de har blitt godkjent",
    },
    myCashSettlement: {
      title: "Kasseoppgjør",
      settle: "Ta kasseoppgjør",
      orders: "Grunnlag for kasseoppgjør",
      ordersDescription: "motatte betalinger siden sist kasseoppgjør",
      ordersDescriptionSingular: "En mottat betaling siden sist kasseoppgjør",
      archive: "Arkiv",
      archiveDescription: "Se tidligere kasseoppgjør",
      settleSuccessTitle: "Kasseoppgjør utført",
      settleSuccessDescription: "Du har sendt inn kasseoppgjøret"
    },
    cashSettlementOrders: {
      title: "Grunnlag for kasseoppgjør",
      orderNumber: "Ordre",
      paymentMethodCard: "med kort",
      paymentMethodCash: "med kontant",
      paymentMethodMobilePayment: "med app"
    },
    cashSettlementArchive: {
      title: "Tidligere kasseoppgjør",
      emptyStateTitle: "Ingen tidligere kasseoppgjør",
      emptyStateDescription: "Her dukker dine tidligere kasseoppgjør opp."
    },
    cashSettlementSummary: {
      title: "Oppsummering",
      cash: "Kontant",
      card: "Kort",
      mobilePayment: "App",
      sum: "Totalt",
    },
    cashSettlementInfo: {
      title: "Info",
      signed: "Signert",
      signedBy: "Signert av",
      approved: "Godkjent",
      approvedBy: "Godkjent av"
    },
    cashOrders: {
      title: "Kontantordre",
      paymentRequestedTab: "App forespurt",
      readyForJournalingTab: "Klar for føring",
      journalsTab: "Journalført",
      journalEntriesTab: "Journal"
    },
    requestedPayments: {
      fieldNumber: "Ordrenr.",
      fieldMission: "Oppdrag",
      fieldDate: "Forespurt dato",
      fieldCustomer: "Kunde",
      fieldSumTotal: "Beløp",
      fieldDeductible: "Egenandel",
      fieldSender: "Forespurt av",
      emptyStateTitle: "Ingen forespurte betalinger til godkjenning",
      emptyStateDescription: "Her dukker forespurte betalinger opp når de venter på godkjenning"
    },
    orderFields: {
      fieldNumber: "Ordrenr.",
      fieldMission: "Oppdrag",
      fieldCustomer: "Kunde",
      fieldSumTotal: "Beløp",
      fieldDeductible: "Egenandel",
      fieldSender: "Forespurt av",
      fieldPaymentMethod: "Betalingsmetode",
    },
    cashOrdersReadyForExport: {
      emptyStateTitle: "Ingen kontantordre klar for journalføring",
      emptyStateDescription: "Her dukker kontantordre opp når de er klar for å bli journalført",
      exportAllTitle: "Journalfør",
      exportAllDescriptionPart1: "Du har valgt",
      exportAllDescriptionPart2: "ordre til journalføring.",
      exportAllButton: "Journalfør",
      exportAllDialog: {
        title: "Bekreft journalføring",
        description: "Du vil nå journalføre flere ordre. Denne handlingen kan ikke angres.",
        confirmText: "Journalfør"
      }
    },
    missingExportSettings: {
      title: "Manglende journalinnstillinger",
      description: "Du mangler innstillinger for journalføring. For å kunne journalføre fakturaene dine, vennligst gå til journalinnstillinger og legg til de manglende feltene.",
      action: "Legg til"
    },
    cashJournals: {
      fieldNumber: "Journalføringsnr.",
      fieldSender: "Forespurt av",
      fieldTotalSum: "Totalt beløp",
      fieldJournalLineCount: "Genererte linjer",
      fieldState: "Status",
      fieldOrderCount: "Ordre",
      emptyStateTitle: "Ingen kontantføringer",
      emptyStateDescription: "Her dukker forespurte kontantføringer opp"
    },
    cashExport: {
      title: "Kontantføring",
      journalLines: "Linjer",
      orders: "Ordre",
      exportTitle: "Eksporter",
      exportSelect: "Velg eksport",
      downloadButton: "Last ned",
      sendButton: "Send",
      working: "Jobber..",
      filesSubHeader: "Filer",
      integrationsSubHeader: "Integrasjoner",
      history: "Historikk eksport",
      ordersEmptyStateTitle: "Ingen ordre inkludert i føringen",
      ordersEmptyStateDescription: "Her dukker ordre som er inkludert i føringen opp",
    },
    invoiceExport: {
      title: "Fakturaføring",
      journalLines: "Linjer",
      orders: "Ordre",
      exportTitle: "Eksporter",
      exportSelect: "Velg eksport",
      downloadButton: "Last ned",
      sendButton: "Send",
      working: "Jobber..",
      filesSubHeader: "Filer",
      integrationsSubHeader: "Integrasjoner",
      history: "Historikk eksport",
      ordersEmptyStateTitle: "Ingen ordre inkludert i føringen",
      ordersEmptyStateDescription: "Her dukker ordre som er inkludert i føringen opp",
    },
    exportHistory: {
      created: "Tidspunkt",
      export: "Eksport",
      exportedBy: "Eksportert av",
      emptyStateTitle: "Ingen historikk",
      emptyStateDescription: "Her loggføres utførte eksporter."
    },
    invoiceJournalEntries: {
      fieldOrderNumber: "Ordrenr.",
      fieldProductCode: "Produktkode",
      fieldProductName: "Produktnavn",
      fieldNumber: "Journalnr.",
      fieldSender: "Forespurt av",
      fieldPrice: "Beløp",
      fieldQuantity: "Kvanta",
      fieldDiscount: "Rabatt",
      fieldAccount: "Konto",
      fieldVatCode: "MVA-kode",
      fieldCustomer: "Kunde",
      fieldCustomerNumber: "Kunde.nr",
      fieldOrganizationNumber: "Org.nr",
      fieldOrderDate: "Ordredato",
      fieldDueDate: "Forfall",
      fieldBillingAddress: "Adresse",
      fieldBillingCity: "By",
      fieldBillingPostNumber: "Postnummer",
      emptyStateTitle: "Ingen journal linjer",
      emptyStateDescription: "Her dukker ferdige journalførte kontant-journallinjer opp",
    },
    cashJournal: {
      fieldOrderNumber: "Ordrenr.",
      fieldProductCode: "Produktkode",
      fieldProductName: "Produktnavn",
      fieldNumber: "Journalnr.",
      fieldSender: "Forespurt av",
      fieldCostPrice: "Pris sum",
      fieldUnitPrice: "Pris stk",
      fieldGrossPrice: "Brutto",
      fieldQuantity: "Kvanta",
      fieldAccount: "Konto",
      fieldVatCode: "MVA-kode",
      fieldItem: "Produkt",
      fieldRow: "Rad",
      fieldConfirmedBy: "Godkjent av",
      emptyStateTitle: "Ingen journal linjer",
      emptyStateDescription: "Her dukker ferdige journalførte kontant-journallinjer opp"
    },
    invoicing: {
      title: "Fakturering",
      openOrdersTab: "Åpne ordre",
      readyForJournalingTab: "Klar for føring",
      journalsTab: "Journalført",
      journalEntriesTab: "Journal",
    },
    openInvoiceOrders: {
      emptyStateTitle: "Ingen åpne ordre",
      emptyStateDescription: "Her dukker åpne ordre opp",
      fieldNumber: "Ordrenr.",
      fieldMission: "Oppdragsnr.",
      fieldStorageState: "Lagerstatus",
      storageCompleted: "Utlevert",
      storageNotCompleted: "På lager",
      noStorage: "Ingen lagring",
      fieldTaskState: "Tjenestestatus",
      tasksCompleted: "Utført",
      tasksNotCompleted: "Pågår",
      fieldMissionCreated: "Oppdrag opprettet",
      fieldCustomer: "Kunde",
      fieldSumTotal: "Beløp",
      fieldDeductible: "Egenandel"
    },
    readyForInvoicing: {
      emptyStateTitle: "Ingen ordre klar for fakturering",
      emptyStateDescription: "Her dukker ordre som er klare til å bli fakturert opp",
      fieldNumber: "Ordrenr.",
      fieldMission: "Oppdragsnr.",
      fieldStorageState: "Lagerstatus",
      storageCompleted: "Utlevert",
      storageNotCompleted: "På lager",
      noStorage: "Ingen lagring",
      fieldTaskState: "Tjenestestatus",
      tasksCompleted: "Utført",
      tasksNotCompleted: "Pågår",
      fieldMissionCreated: "Oppdrag opprettet",
      fieldCustomer: "Kunde",
      fieldSumTotal: "Beløp",
      fieldDeductible: "Egenandel",
      exportAllTitle: "Journalfør",
      exportAllDescriptionPart1: "Du har valgt",
      exportAllDescriptionPart2: "ordre til journalføring.",
      exportAllButton: "Journalfør",
      exportAllDialog: {
        title: "Bekreft føring",
        description: "Du vil nå journalføre flere ordre. Denne handlingen kan ikke angres.",
        confirmText: "Journalfør"
      }
    },
    invoiceJournals: {
      fieldNumber: "Journalføringsnr.",
      fieldSender: "Forespurt av",
      fieldTotalSum: "Totalt beløp",
      fieldJournalLineCount: "Genererte linjer",
      fieldOrderCount: "Ordre",
      fieldState: "Status",
      emptyStateTitle: "Ingen fakturaføringer",
      emptyStateDescription: "Her dukker forespurte fakturaføringer opp"
    },
    cashExportLines: {
      deductible: "Egenandel",
    },
    invoiceExportLines: {
      object: "Kjøretøy",
      registration: "Reg.nr",
      missionType: "Oppdragstype",
      cause: "Skadeårsak",
      insuranceCompany: "Forsikring",
      requester: "Rekvirent",
      storage: "Lagring",
      created: "Opprettet",
      agreementReference: "Avtalereferanse",
      day: "dag",
      days: "dager",
      transport: "Transport til",
      assistance: "Assistanse",
      deductible: "Egenandel",
      vatTransfer: "Flytting av MVA",
      vatTransferSummary: "Netto beløp (uten MVA) dekket av forsikring. Ordre",
      defaultUnit: "Stk",
      comment: "Kommentar faktura"
    },
    storage: {
      title: "Lager",
      emptyStateTitle: "Ingen objekter til lagring",
      emptyStateDescription: "Her dukker aktive lagertjenester opp",
      createStorage: "Ny lagring",
      table: {
        mission: "Oppdrag",
        stored: "Lagret",
        warehouse: "Lager",
        keyNumber: "Nøkkelnummer",
        reminder: "Neste varsling",
        daysInStorage: "Dager på lager",
        storageCost: "Påløpt lagerleie",
        comment: "Kommentar",
      }
    },
    cause: {
      title: "Skadeårsak",
      create: "Legg til skadeårsak",
      edit: "Rediger skadeårsak",
      emptyStateTitle: "Ingen skadeårsaker lagt til enda",
      emptyStateDescription: "Trykk “Legg til” for å legge til skadeårsaker",
      fieldTitle: "Årsak",
    },
    serviceVehicle: {
      title: "Servicekjøretøy",
      create: "Legg til servicebil",
      edit: "Rediger servicebil",
      emptyStateTitle: "Ingen servicekjøretøy lagt til enda",
      emptyStateDescription: "Trykk “Legg til” for å legge til servicekjøretøy",
      fieldTitle: "Navn kjøretøy",
      fieldActive: "Status",
      fieldDriver: "Aktiv sjåfør",
      fieldDescription: "Beskrivelse",
      fieldActiveLabelTrue: "Aktiv",
      fieldActiveLabelFalse: "Ute av drift"
    },
    vatCode: {
      title: "Koder for merverdiavgift",
      create: "Legg til kode",
      edit: "Rediger kode",
      emptyStateTitle: "Ingen koder lagt til enda",
      emptyStateDescription: "Trykk “Legg til” for å legge til koder",
      fieldName: "Navn",
      fieldCode: "MVA-kode",
      fieldRate: "Sats"
    },
    productGroup: {
      title: "Produktgrupper",
      create: "Legg til gruppe",
      edit: "Rediger gruppe",
      emptyStateTitle: "Ingen grupper lagt til enda",
      emptyStateDescription: "Trykk “Legg til” for å legge til grupper",
      fieldCode: "Nummer",
      fieldName: "Navn",
      fieldAccount: "Konto",
      fieldVatCode: "MVA-kode",
      fieldDescription: "Beskrivelse"
    },
    product: {
      title: "Produkt",
      create: "Legg til produkt",
      edit: "Rediger produkt",
      emptyStateTitle: "Ingen produkter lagt til enda",
      emptyStateDescription: "Trykk “Legg til” for å legge til produkter",
      fieldName: "Produktnavn",
      fieldCode: "Produktkode",
      fieldPrice: "Standardpris",
      fieldVAT: "MVA (%)",
      fieldProductGroup: "Produktgruppe",
      fieldAccount: "Konto",
      fieldDescription: "Notat",
      vat0: "0% - Ingen MVA",
      vat12: "12% - Lav rate",
      vat15: "15% - Standard rate",
      vat25: "25% - Høy rate"
    },
    warehouseRegister: {
      title: "Lagerlokasjoner",
      create: "Legg til lagerlokasjon",
      edit: "Rediger lagerlokasjon",
      emptyStateTitle: "Ingen lagerlokasjoner lagt til enda",
      emptyStateDescription: "Trykk “Legg til” for å legge til lagerlokasjoner",
      fieldName: "Lagernavn",
      fieldAddress: "Addresse"
    },
    contactRegister: {
      title: "Kontakter",
      addPre: "Legg til",
      addPost: "som ny kontakt",
      create: "Legg til kontakt",
      all: "Alle kontakter",
      customers: "Kunder",
      edit: "Rediger kontakt",
      emptyStateTitle: "Ingen kontakter lagt til enda",
      emptyStateDescription: "Trykk “Legg til” for å legge til kontakter",
      fieldContactHeader: "Kontaktinfo",
      fieldNumber: "#",
      fieldName: "Navn",
      fieldAddress: "Adresse",
      fieldEmail: "E-post",
      fieldPhoneNumber: "Telefon",
      fieldOrganizationNumber: "Org.nr",
      fieldDescription: "Notat",
      fieldIsCustomer: "Kunde",
      fieldCustomerHeader: "Kundeinfo",
      fieldCustomerNumber: "Kundenummer",
      fieldBillingCountry: "Land",
      fieldBillingAddress: "Fakturaadresse",
      fieldBillingAddress2: "Fakturaadresse linje 2",
      fieldPostNumber: "Postnummer",
      fieldArea: "Sted",
      fieldBillingEmail: "Faktura e-post",
      fieldContactPersonName: "Navn",
      fieldContactPersonEmail: "E-post",
      fieldContactPersonPhoneNumber: "Telefon",
      fieldContactPersonDescription: "Merknad",
      fieldContactPersonLocation: "Addresse",
      fieldStandardAgreement: "Standard avtale"
    },
    contact: {
      title: "Kontakt",
      delete: "Slett kontakt",
      tabs: {
        contactInformationTab: "Kontaktinfo",
        missionsTab: "Oppdrag",
        ordersTab: "Ordre"
      },
      contactInformationCard: {
        title: "Kontakt",
        address: "Addresse",
        email: "E-post",
        organizationNumber: "Org.nr",
        description: "Notat",
        standardAgreement: "Standard avtale",
        edit: "Endre",
        editDialogTitle: "Rediger kontakt",
        addCustomerInformation: "Legg til kundedata"
      },
      customerInformationCard: {
        missingInformation: "Det mangler vikting faktureringsinformasjon om denne kunden, trykk på “Endre” for å legge det til. Denne kunden dukker ikke opp i kundesøk før dette er gjort.",
        title: "Kunde",
        customerNumber: "Kundenummer",
        billingEmail: "Faktura e-post",
        billingAddress: "Faktura addresse",
        edit: "Endre",
        editDialogTitle: "Rediger kunde"
      },
      contactPersonsCard: {
        title: "Kontaktpersoner",
        add: "Ny kontaktperson",
        edit: "Rediger kontaktperson",
        emptyStateTitle: "Ingen kontaktpersoner",
        emptyStateDescription: "Trykk på “Ny kontaktperson” for å legge til kontaktpersoner"
      },
      contactMissionsCard: {
        emptyStateTitle: "Ingen oppdrag",
        emptyStateDescription: "Her dukker oppdrag som er knyttet til denne kontakten opp"
      },
      contactOrdersCard: {
        emptyStateTitle: "Ingen ordre",
        emptyStateDescription: "Her dukker ordre som er knyttet til denne kontakten opp",
        fieldMissionNumber: "Oppdragsnr",
        fieldTotal: "Sum",
        fieldPaymentMethod: "Betalingsmetode"
      }
    },
    agreementRegister: {
      title: "Avtaler",
      create: "Legg til avtale",
      all: "Alle avtaler",
      agreements: "Avtaler",
      edit: "Rediger avtale",
      emptyStateTitle: "Ingen avtaler lagt til enda",
      emptyStateDescription: "Trykk “Legg til” for å legge til avtaler",
      fieldName: "Navn",
      fieldDefaultAccount: "Standard fakturakunde",
      fieldDiscounts: "Antall justerte produktpriser",
      fieldDescription: "Viktig info"
    },
    agreement: {
      delete: "Slett avtale",
      agreementInformationCard: {
        title: "Avtaleinfo",
        name: "Navn",
        description: "Viktig info",
        defaultAccount: "Standard fakturakunde",
        defaultAccountInfo: "Standard kontakt som faktureres for denne avtalen"
      },
      discountedProductsCard: {
        title: "Avtalepriser",
        add: "Ny avtalepris",
        edit: "Juster avtalepris",
        emptyStateTitle: "Ingen avtalepriser",
        emptyStateDescription: "Trykk på “Ny avtalepris” for å legge til avtalepriser",
        fieldCode: "Produktnummer",
        fieldName: "Produkt",
        fieldPrice: "Pris for avtale",
        fieldLink: "Link",
      }
    },
    driverRegister: {
      title: "Sjåføroversikt",
      edit: "Rediger sjåfør",
      emptyStateTitle: "Ingen sjåfører lagt til enda",
      emptyStateDescription: "Be admin om å legge til noen med sjåfør rollen",
      fieldName: "Navn",
      fieldPhoneNumber: "Telefonnummer",
      fieldState: "Status",
      fieldStateTrue: "På jobb",
      fieldStateFalse: "Ikke på jobb",
      fieldActiveMissions: "Aktive oppdrag",
      fieldVehicle: "Kjøretøy",
      noVehicle: "Ingen kjøretøy",
      vehicleInUseTitle: "Kjøretøyet er allerede i bruk av en annen sjåfør",
      vehicleInUseDescription: "Trykk “Bruk” for å sette over dette kjøretøyet til den valgte sjåføren allikevel"
    },
    missionTags: {
      title: "Oppdragsdetaljer",
      create: "Legg til oppdragsdetalj",
      edit: "Rediger oppdragsdetalje",
      emptyStateTitle: "Ingen detaljer lagt til enda",
      emptyStateDescription: "Trykk “Legg til” for å legge til oppdragsdetaljer",
      fieldTitle: "Oppdragsdetalj",
    },

    task: {
      common: {
        accept: "Aksepter oppdrag",
        reject: "Avslå",
        cancelledTitle: "Oppdrag avbestilt",
        cancelledDescription: "Dette oppdraget har blitt avbestilt."
      },
      enums: {
        state: {
          None: "Ingen status",
          Created: "Opprettet",
          Started: "På vei",
          Arrived: "Ankommet oppdragssted",
          Loaded: "Opplastet",
          Completed: "Utført",
          Acknowledged: "Kvittert"
        },
        stateShorthand: {
          None: "Ingen status",
          Created: "Opprettet",
          Started: "På vei",
          Arrived: "Ankommet",
          Loaded: "Opplastet",
          Completed: "Utført",
          Acknowledged: "Kvittert"
        },
        driverAssignedState: {
          Unknown: "Ingen sjåfør",
          Pending: "Venter på svar",
          Accepted: "Akseptert",
          Rejected: "Avslått"
        }
      }
    },
    order: {
      customer: {
        title: "Kunde",
        name: "Kundenavn",
        comment: "Intern kommentar",
        suggestions: "Forslag:",
        owner: "Eier",
        requester: "Rekvirent",
        agreement: "Avtale"
      },
      orderLines: {
        title: "Ordrelinjer",
        productTitle: "Produkt",
        sumTitle: "Sum",
        addReferencedProduct: "Produktlinje",
        addTextProduct: "Fritekstlinje",
        empty: "Ingen ordrelinjer"
      },
      orderLineItem: {
        vat: "mva",
        unit: "stk",
        at: "à",
        discount: "rabatt"
      },
      referencedOrderLineItemDialog: {
        editTitle: "Rediger produktlinje",
        createTitle: "Ny produktlinje",
        search: "Produkt",
        price: "Pris",
        quantity: "Antall",
        discount: "Rabatt",
        vat: "MVA",
        code: "Produktnummer",
        description: "Notat",
        create: "Legg til",
        edit: "Endre",
        cancel: "Avbryt",
        delete: "Fjern"
      },
      freeTextOrderLineItemDialog: {
        editTitle: "Rediger produktlinje",
        createTitle: "Ny fritekst-linje",
        productName: "Produktnavn",
        price: "Pris",
        quantity: "Antall",
        discount: "Rabatt",
        vat: "MVA",
        code: "Kode",
        description: "Beskrivelse",
        productGroup: "Produktgruppe",
        account: "Regnskapskonto",
        create: "Legg til",
        edit: "Endre",
        cancel: "Avbryt",
        delete: "Fjern"
      },
      summary: {
        title: "Oppsummering",
        production: "Beløp produksjon",
        vat: "Merverdiavgift",
        sumProduction: "Sum produksjon",
        sumToPay: "Å betale",
        addDeductible: "Egenandel",
        addVatMoving: "Overfør MVA",
        deductible: "Egenandel",
        to: "til",
        from: "fra",
        order: "Ordre",
        vatTransfer: "MVA overført"
      },
      checkout: {
        title: "Betaling",
        paymentMethod: "Betalingsmåte",
        selectPaymentMethod: "Velg betalingsmåte"
      },
      deductibleDialog: {
        title: "Rediger egenandel",
        save: "Lagre",
        cancel: "Avbryt",
        delete: "Fjern",
        order: "Ordre",
        create: "Legg til ny ordre",
        price: "Egenandel",
        transfer: "Mottar egenandel",
        none: "Ingen ordre",
        convertToInvoice: "Valgt ordre vil bli konvertert til å bruke faktura betalingsmåte."
      },
      vatTransferDialog: {
        title: "Overfør MVA fra forsikringselskap til kunde",
        description: "Bruk denne funksjonen til å overføre merverdiavgift fra forsikringsselskapet til bedriftskunder sin faktura.",
        linkText: "Lær mer om overføring av MVA.",
        order: "Til",
        none: "Ingen ordre",
        create: "Legg til ny ordre",
        save: "Lagre",
        cancel: "Avbryt",
        delete: "Fjern",
        convertToInvoice: "Valgt ordre vil bli konvertert til å bruke faktura betalingsmåte.",
        convertSelfToInvoice: "Denne ordre vil bli konvertert til å bruke faktura betalingsmåte."
      },
      invoiceFlow: {
        readyForExport: "Klar for føring",
        missingContact: "For å journalføre en faktura må du velge en registrert kunde fra “kundenavn”-feltet. Søk opp eller opprett en ny kunde først.",
        relatedOrderConflicting: "Relatert ordre må lukkes før denne kan markeres som “Klar for føring.",
        contactPerson: "Kontaktperson",
        noContactPerson: "Ingen kontaktperson",
        reopen: "Åpne for redigering",
        export: "Journalfør nå",
        exportDialog: {
          title: "Bekreft føring",
          description: "Du vil nå journalføre og lukke ordren permanent. Er du sikker på at du vil fortsette?",
          confirmText: "Journalfør",
          workingTitle: "Eksporterer",
          close: "Lukk",
          openJournal: "Se journal",
          successTitle: "Eksport vellykket",
          successDescription: "Valgte ordre er nå eksportert.",
          errorTitle: "Feil oppdaget under eksport",
          errorDescription: "Vi stoppet eksporten fordi vi mottok følgende feil:",
          pleaseFix: "Vennligst rett opp feilen og prøv igjen når alt er i orden.",
          unknownError: "Ukjent feil",
          ok: "Ok",
        }
      },
      invoiceInformation: {
        orderDate: "Ordredato",
        address: "Adresse",
        edit: "Endre",
        state: "Status",
        title: "Status",
        invoice: "Fakturainfo",
        date: "Dato",
        person: "Person",
        comment: "Kommentar",
        reference: "Referanse",
        organizationNumber: "Org.nr.",
        customerNumber: "Kundenr.",
        contactPerson: "Kontakt",
        email: "Epost",
      },
      invoiceInformationDialog: {
        title: "Rediger fakturainfo",
        orderDate: "Ordredato",
        reference: "Referanse",
        comment: "Kommentar",
        save: "Lagre",
        cancel: "Avbryt",
        delete: "Fjern",
      },
      requestFlow: {
        service: "Tjeneste",
        requestPayment: "Betaling forespurt",
        confirmPayment: "Bekreft betaling",
        sendReceipt: "Del kvittering",
        viewReceipt: "Se kvittering"
      },
      requestInformation: {
        title: "Status",
        state: "Status",
        stateRequested: "Forespurt",
        stateConfirmed: "Betalt",
        service: "Tjeneste",
        requestedDate: "Forespurt dato",
        confirmedDate: "Betalt dato",
        person: "Person",
        settlement: "Kasseoppgjør",
        settled: "Oppgjort",
        notSettled: "Ikke oppjort"
      },
      paymentConfirmedDialog: {
        title: "Bekreft betaling",
        description: "Er betalingen mottatt?",
        confirmText: "Betaling mottatt"
      },
      paymentRequestedDialog: {
        title: "Bekreft betaling forespurt",
        description: "Er betalingen forespurt?",
        confirmText: "Betaling forespurt"
      },
      terminalFlow: {
        comment: "Kommentar (ikke påkrevd)",
        confirmPayment: "Bekreft betaling",
        sendReceipt: "Del kvittering",
        viewReceipt: "Se kvittering",
        service: "Tjeneste"
      },
      terminalInformation: {
        title: "Status",
        state: "Status",
        stateConfirmed: "Betalt",
        paymentMethod: "Type betaling",
        confirmedDate: "Dato",
        person: "Person",
        settlement: "Kasseoppgjør",
        settled: "Oppgjort",
        notSettled: "Ikke oppjort",
        service: "Tjeneste",
        comment: "Kommentar"
      },
      receipt: {
        title: "Kvittering",
        productTitle: "Produkt",
        sumTitle: "Sum",
        seller: "Selger",
        orderNumber: "Ordrenr.",
        missionNumber: "Oppdragsnr.",
        missionType: "Oppdragstype",
        registration: "Reg.nr",
        paymentMethod: "Betalingsmetode",
        person: "Person",
        date: "Betalt",
        created: "Opprettet",
        shareTitle: "Del kvittering",
        cause: "Skadeårsak",
        comment: "Kommentar",
        agreementReference: "Avtalereferanse"
      },
      receiptDialog: {
        title: "Kvittering",
        send: "Del kvittering",
        download: "Last ned",
        close: "Lukk"
      },
      enums: {
        currency: {
          None: "Ukjent",
          NOK: "NOK",
          USD: "USD"
        },
        currencyShortHand: {
          None: "???",
          NOK: "kr",
          USD: "$"
        },
        paymentMethod: {
          None: "Ukjent",
          Invoice: "Faktura",
          MobilePaymentRequest: "App - Forespurt",
          Cash: "Kontant",
          Card: "Kort",
          MobilePayment: "App"
        },
        invoiceState: {
          None: "Opprettet",
          Queued: "Klar til fakturering",
          Pending: "Kjører fakturering..",
          Finished: "Journalført"
        },
        orderState: {
          None: "Ukjent",
          Open: "Åpen",
          Ready: "Klar",
          Invoiced: "Fakturert",
          PaymentRequested: "Forespurt",
          PaymentConfirmed: "Betalt"
        },
        missionOrderState: {
          None: "Ingen ordre",
          Open: "Åpen",
          Closed: "Lukket"
        }
      }
    },
    mission: {
      mission: "Oppdrag",
      deleteDraft: "Slett utkast",
      createMission: "Opprett oppdrag",
      cancelMission: "Avbestill",
      completeMission: "Fullfør oppdrag",
      reactivateMission: "Reaktiver",
      addFilesCard: "Legg til filer",
      addServiceDetails: "Legg til service info",
      enums: {
        type: {
          Unknown: "Ukjent",
          Salvage: "Berging",
          Towing: "Tauing",
          Other: "Andre"
        },
        state: {
          None: "Ukjent",
          Draft: "Utkast",
          Cancelled: "Avbestillt",
          Created: "Åpent",
          Completed: "Fullført",
          Archived: "Arkivert"
        },
        cause: {
          Unknown: "Ukjent"
        },
        targetType: {
          Unknown: "Ukjent",
          Car: "Bil",
          Boat: "Båt",
          Other: "Andre"
        },
        driveTrain: {
          Unknown: "Ukjent",
          FWD: "Forhjulsdrift",
          RWD: "Bakhjulsdrift",
          AWD: "Firehjulsdrift",
          Other: "Andre"
        },
        locationState: {
          Unknown: "Ukjent",
          NoOneAtLocation: "Ingen på plass",
          InPlace: "På plass",
          PoliceWaiting: "Politiet venter",
          Phone: "Telefon",
        },
        insuranceState: {
          Unknown: "Ukjent",
          NotOK: "Ikke OK",
          OKWithDeviations: "OK med mangler",
          OKAccordingToCustomer: "OK ifølge kunde",
          OK: "OK",
        },
        insuranceType: {
          Unknown: "Ukjent",
          Liability: "Ansvar",
          PartialCasco: "Delkasko",
          Casco: "Kasko",
          Other: "Andre",
        },
        driverAssignedState: {
          Unknown: "Ukjent",
          Pending: "Venter på svar",
          Accepted: "Akseptert oppdrag",
          Rejected: "Avslått oppdrag",
        },
        fuelType: {
          Unknown: "Ukjent",
          Petrol: "Bensin",
          Diesel: "Diesel",
          Electric: "Elektrisk",
          Other: "Andre"
        }
      },
      cards: {
        orderCards: {
          title: "Betaling",
          add: "Ny ordre",
          deleteDialog: {
            title: "Er du sikker på at du vil slette denne ordren?",
            description: "Dette kan ikke reverseres",
            action: "Slett ordre"
          }
        },
        orderCard: {
          title: "Ordre",
          number: "Ordrenr.",
          delete: "Slett ordre"
        },
        details: {
          title: "Oppdrag",
          service: "Info",
          type: "Oppdragstype",
          cause: "Skadeårsak",
          description: "Oppdragsdetaljer"
        },
        target: {
          object: "Objekt",
          type: "Type objekt",
          typeCar: "Bil",
          typeBoat: "Båt",
          typeOther: "Andre",
          description: "Detaljer",
          registration: "Reg.nummer",
          make: "Merke",
          model: "Modell",
          color: "Farge",
          driveTrain: "Drift aksling",
          length: "Lengde (fot)",
          weight: "Vekt (kg)",
          fuel: "Drivstoff",
          chassisNumber: "Understellsnummer",
          year: "Årstall",
          ownerName: "Eier",
          ownerAddress: "Eiers adresse",
          leaseName: "Leasingtaker",
          leaseAddress: "Leasingtakers adresse",
          searchDialog: {
            title: "Informasjon om",
            description: "Følgende data er registrert hos kjøretøyregisteret:",
            cancel: "Avbryt",
            confirm: "Legg til",
            confirmOverwrite: "Legg til og overskriv",
            notFound: "Vi fant ingen oppslag under",
            error: "Det har skjedd en feil"
          }
        },
        actors: {
          title: "Interessenter",
          actors: {
            owner: "Eier",
            requester: "Rekvirent",
            name: "Navn",
            address: "Adresse",
            email: "E-post",
            phoneNumber: "Tlf",
            ownerNotRequester: "Rekvirent og eier er forskjellige",
            addContactPerson: "Legg til kontaktperson",
            contactPerson: "Kontaktperson"
          },
          contract: {
            title: "Avtaleinfo",
            agreement: "Avtale",
            noAgreement: "Ingen avtale",
            deductible: "Egenandel",
            reference: "Referanse",
            comment: "Kommentar avtale",
            insurance: "Forsikring",
            insuranceCompany: "Forsikringsselskap",
            insuranceType: "Type forsikring",
            insuranceState: "Status forsikring"
          }
        },
        tasks: {
          title: "Tjenester",
          create: "Ny tjeneste",
          map: {
            detailsCardTitle: "Kilometer",
            detailsCardEdit: "Endre",
            distanceInRoute: "Transport",
            distanceToRoute: "Til/fra stasjon",
            distanceTotal: "Totalt",
            overrideDialog: {
              title: "Overstyr distanse kjørt",
              overriddenDistanceInRoute: "Overstyr distanse i rute (Transport)",
              overriddenDistanceToRoute: "Overstyr distanse til/fra stasjon",
              overriddenDistanceInRouteLabel: "Overstyrt verdi - distanse i rute",
              overriddenDistanceToRouteLabel: "Overstyrt verdi - distanse til/fra stasjon",
              overrideReasonLabel: "Begrunnelse for overstyring",
              cancel: "Avbryt",
              confirm: "Endre"
            }
          },
          menu: {
            createStorage: "Lagring",
            createAssistance: "Assistanse",
            createNamedTask: "Egendefinert tjeneste"
          },
          createNamedTaskDialog: {
            title: "Navngi tjeneste",
            description: "Sett navnet som tjenesten skal ha",
            label: "Navn på jobb",
            confirmText: "Opprett"
          },
          salvageTask: {
            defaultName: "Assistanse",
            missionLocation: "Oppdragssted",
            waypoint: "Stoppested",
            transportTo: "Transport til",
            addWaypointButton: "Legg til stoppested",
            addTransportButton: "Legg til transport",
            customerWaiting: "Kunde venter",
            keyPlacement: "Nøkkelplassering",
            description: "Beskrivelse oppdragssted",
            comment: "Kommentar tjeneste",
            eta: "Estimert ankomst",
            onDeleteTitle: "Slett tjeneste",
            onDeleteDescription: "Er du sikker på at du vil slette denne tjenesten?",
            onDeleteConfirm: "Slett",
          },
          storageTask: {
            defaultName: "Lagring",
            stored: "Lagret dato",
            comment: "Kommentar lagring",
            warehouse: "Lagringsplass",
            keyNumber: "Nøkkelnummer",
            reminder: "Påminn om varsling",
            protected: "Krever godkjenning for utlevering",
            rate: "Sats per dag (kr)",
            onDeleteTitle: "Slett tjeneste",
            onDeleteDescription: "Er du sikker på at du vil slette denne tjenesten?",
            onDeleteConfirm: "Slett",
            onDeliverTitle: "Utlever objekt",
            onDeliverDescription: "Er du sikker på at du vil utlevere dette objektet?",
            onDeliverConfirm: "Utlever",
            state: {
              title: "Status",
              elapsedTime: "Dager",
              accumulatedCosts: "Akk. lagring",
              delivered: "Utlevert"
            }
          },
          driver: {
            title: "Sjåfør",
            selectDriver: "Velg sjåfør",
            unSelectDriver: "Ingen sjåfør",
            driverActive: "På jobb",
            driverInactive: "Ikke på jobb",
            eta: "Estimert ankomst",
            phoneNumber: "Telefonummer",
            activeCar: "Aktiv bil",
            history: "Historie",
            driverState: "Status sjåfør",
            states: {
              title: "Status",
              created: "Opprettet",
              started: "På vei",
              arrived: "Ankomst",
              loaded: "Opplastet",
              completed: "Utført",
              acknowledged: "Kvittert",
            },
            state: {
              datetime: "tidspunkt",
              add: "Legg til status",
              edit: "Endre status",
              cancel: "Avbryt",
              delete: "Slett",
              confirmAdd: "Sett status",
              confirmEdit: "Endre",
              setToCurrentTime: "Sett på nåværende tidspunkt"
            }
          },
        },
        service: {
          title: "Service",
          remove: "Fjern serviceinfo",
          lastService: "Sist service",
          serviceCenter: "Serviceverksted",
          validTo: "Service gyldig til",
          mileageAtService: "Km-stand ved service",
          mileage: "km-stand",
          validWarranty: "Gyldig mobilitetsgaranti"
        },
        files: {
          title: "Filer",
          upload: "Last opp",
          imagesHeader: "Bilder",
          documentsHeader: "Dokumenter",
          onDeleteTitle: "Slett fil",
          onDeleteDescription: "Er du sikker på at du vil slette denne filen?",
          onDeleteConfirm: "Slett",
          emptyStateTitle: "Her er det tomt",
          emptyStateDescription: "Trykk “+ Last opp” for å legge til bilder eller laste opp dokumenter."
        },
      },
      closeDialog: {
        readyTitle: "Fullføre oppdraget?",
        warnTitle: "Fullføre ufullstendig oppdrag?",
        readyDescription: "Oppdraget er komplett og klart til å lukkes, med mindre du har flere planer fremover.",
        warnDescription: "Det ser ut som oppdraget ikke er komplett. Hvis du fullfører nå, kan det føre til manglende data eller ufullstendig arbeid. Er du sikker på at du vil fortsette?",
        tasksReady: "Alle tjenester lukket",
        tasksWarn: "Lukk åpen tjeneste",
        tasksWarnDescription: "Du har en eller flere åpne tjenester. Marker aktiv tjeneste med “Utført” for å lukke den.",
        storageReady: "Lagring utlevert",
        storageWarn: "Marker lagring som utlevert",
        storageWarnDescription: "Lagringen er ikke markert som utlevert. Marker den som utlevert, eller fjern lagringen.",
        ordersReady: "Alle ordre lukket",
        ordersWarn: "Åpen ordre må lukkes",
        ordersWarnDescription: "Du har en ordre som ikke er lukket enda. Marker den som betalt, eller journalført for å lukke den. ",
        ordersError: "Opprett ordre",
        ordersErrorDescription: "Dette oppdraget mangler en ordre. Lag en ordre og lukk den for å fullføre oppdraget.",
        cancel: "Lukk",
        complete: "Fullfør oppdrag",
        completeWarn: "Fullfør likevel"
      }
    },
    stationSettings: {
      header: "Innstillinger",
      title: "Stasjon",
      displayName: "Navn",
      legalName: "Juridisk navn stasjon",
      legalNameDescription: "Navn som brukes på faktura",
      location: "Addresse",
      includeDistance: "Inkluder kjøring til og fra stasjon på alle oppdrag",
      includeDistanceDescription: "Legg til avstand fra stasjon til skadested, og destinasjon til stasjon til kilometer kjørt på kalkuleringen.",
      save: "Lagre endringer",
      logoEditor: {
        edit: "Endre logo",
        ok: "Ferdig",
        cancel: "Avbryt",
        upload: "Klikk eller dra for å velge"
      }
    },
    exportSettings: {
      header: "Innstillinger",
      title: "Journalinnstillinger",
      description: "Juster hvordan du ønsker Assist skal utføre journalføring av faktura og kontantoppgjør.",
      accountsTitle: "Regnskapskontoer for betalinger",
      accountsDescription: "Legg til kontoer som du ønsker Assist skal bruke på ulike betalingsmåtene når vi lager føringer til regnskapssystemet ditt.",
      mobilePaymentAccount: "App",
      cashAccount: "Kontant",
      cardAccount: "Terminal",
      transferTitle: "Eksport av egenandel og MVA-fakturering",
      transferDescription: "Velg hvordan vi skal behandle egenandel og MVA-fakturering mellom to ordre når vi lager journalfiler til deg. Normalt sett vil det ikke være MVA på slike transaksjoner.",
      deductibleSelect: "Produktgruppe for egenandel",
      vatSelect: "Produktgruppe MVA fakturering",
      save: "Lagre endringer"
    },
    integrations: {
      title: "Integrasjoner",
      description: "Velkommen til integrasjoner. Her kan du koble Assist til andre systemer som du bruker.",
      activeTitle: "Aktiv",
      allTitle: "Alle integrasjoner",
      library: {
        Tripletex: {
          description: "Innovativt regnskapsverktøy skapt spesielt for små bedrifter. Tilbyr enkel integrasjon med fakturaer, kasseoppgjør og kontakter. Denne integrasjonen er i beta og kan endres.",
          instructions: "Gå til Tripletex, bruk applikasjonsnavnet “Assist” til å hente ut brukernøklene dine, og lim dem inn her.",
          instructionsLink: "Her har du guide til å finne API-brukernøkkel.",
          apiKey: "API-brukernøkkel",
          settings: {
            createContactsIfNotExists: "Opprett kunder som ikke eksisterer under eksport.",
            createProductsIfNotExists: "Opprett produkter og produktgrupper som ikke eksisterer i regnskapsprogrammet ditt under eksport.",
            treatProductsAsProductGroups: "Knytt produkter i Assist til et produkt som representerer hele produktgruppen.",
          },
          errors: {
            tripletexError: "Tripletex har rapportert en feil",
            createContactsIfNotExists: "En feil har skjedd",
            createProductsIfNotExists: "En feil har skjedd",
          },
          errorDescriptions: {
            createContactsIfNotExists: "Denne føringen inneholder en kontakt som ikke eksisterer i Tripletex. Opprett denne kontakten i Tripletex, eller skru på automatisk opprettelse under denne integrasjonens innstillinger.",
            createProductsIfNotExists: "Denne føringen inneholder et produkt som ikke eksisterer i Tripletex. Opprett dette produktet i Tripletex, eller skru på automatisk opprettelse under denne integrasjonens innstillinger."
          }
        },
        Duett: {
          description: "Duett tilbyr brukervennlige økonomiprogrammer og skytjenester som er spesialtilpasset for små og mellomstore bedrifter.",
          instructions: "Gå til Tripletex, hent API-nøklene dine, og lim dem inn her.",
          instructionsLink: "Her har du guide til å finne API-brukernøkkel.",
          apiKey: "API-brukernøkkel",
          settings: {
            createContactsIfNotExists: "Opprett kunder som ikke eksisterer under eksport.",
            createProductsIfNotExists: "Opprett produkter og produktgrupper som ikke eksisterer i regnskapsprogrammet ditt under eksport.",
            treatProductsAsProductGroups: "Knytt produkter i Assist til et produkt som representerer hele produktgruppen.",
          },
          mappings: {
            VatCode: {
              title: "Mapping av Assist-momskoder mot Duett-momskoder",
              vat: "25% - Alminnelig sats",
              food: "15% - Næringsmidler",
              low: "12% - Persontransport, kinobilletter, utleie av rom",
              noVat: "0% - Ingen MVA",
            }
          },
          errors: {
            createContactsIfNotExists: "En feil har skjedd",
            createProductsIfNotExists: "En feil har skjedd",
            duettError: "Duett har rapportert en feil"
          },
          errorDescriptions: {
            createContactsIfNotExists: "Denne føringen inneholder en kontakt som ikke eksisterer i Duett. Opprett denne kontakten i Duett, eller skru på automatisk opprettelse under denne integrasjonens innstillinger.",
            createProductsIfNotExists: "Denne føringen inneholder et produkt som ikke eksisterer i Duett. Opprett dette produktet i Duett, eller skru på automatisk opprettelse under denne integrasjonens innstillinger."
          }
        },
        PowerOfficeGo: {
          description: "PowerOffice er et skreddersydd regnskapssystem for små og mellomstore bedrifter, og har bransjens mest fornøyde kunder.",
          instructions: "Gå til PowerOffice, hent API-nøklene dine, og lim dem inn her.",
          instructionsLink: "Her har du guide til å finne API-brukernøkkel.",
          apiKey: "API-brukernøkkel",
          settings: {
            createContactsIfNotExists: "Opprett kunder som ikke eksisterer under eksport.",
            createProductsIfNotExists: "Opprett produkter og produktgrupper som ikke eksisterer i regnskapsprogrammet ditt under eksport.",
            treatProductsAsProductGroups: "Knytt produkter i Assist til et produkt som representerer hele produktgruppen.",
          },
          errors: {
            createContactsIfNotExists: "En feil har skjedd",
            powerOfficeGoError: "PowerOfficeGo har rapportert en feil"
          },
          errorDescriptions: {
            createContactsIfNotExists: "Denne føringen inneholder en kontakt som ikke eksisterer i PowerOfficeGo. Opprett denne kontakten i PowerOfficeGo, eller skru på automatisk opprettelse under denne integrasjonens innstillinger.",
            createProductsIfNotExists: "Denne føringen inneholder et produkt som ikke eksisterer i PowerOfficeGo. Opprett dette produktet i PowerOfficeGo, eller skru på automatisk opprettelse under denne integrasjonens innstillinger."
          }
        },
      },
      common: {
        sending: "Sender journalføring til integrasjon..",
        sendingSuccess: "Dataene ble sendt vellykket og er straks tilgjengelige i integrasjonen",
        sendingError: "En feil har skjedd under data-innsending",
      },
      resend: {
        title: "Eksportere en gang til?",
        description: "Denne journalføringen er allerede eksportert fra før. Ønsker du å eksportere den igjen?",
        confirmText: "Eksporter igjen"
      }
    },
    integrationCard: {
      add: "Legg til",
      edit: "Rediger"
    },
    integrationDialog: {
      addTitle: "Legg til",
      editTitle: "Rediger",
      create: "Opprett integrasjon",
      save: "Lagre endringer",
      delete: "Fjern",
      apiKeys: "API-nøkler",
      settings: "Innstillinger",
      inputs: {
        name: "Navn integrasjon",
      },
      deleteTitle: "Fjerne integrasjon?",
      deleteDescription: "Er du sikker du vil fjerne integrasjonen? Du kan ikke angre denne handlingen.",
      deleteAction: "Fjern integrasjon",
    },
    updateLocationDialog: {
      title: "Oppdatere oppdragsted?",
      description: "Du har markert “ankommet” et stykke ifra registrert oppdragsted. Vil du oppdatere oppdragsted med din nåværende posisjon?",
      confirmText: "Oppdater oppdragsted",
      cancelText: "Behold oppdragsted"
    }

  }
};


i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "no", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;