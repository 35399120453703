import { makeStyles } from "tss-react/mui";
import { Collapse, Divider, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDevice } from "../../../providers/DeviceProvider";

const useStyles = makeStyles()(
  (theme) => ({
    container: {
      background: theme.palette.background.default,
      borderRadius: theme.spacing(2),
      padding: theme.spacing(3),
      width: "100%",
    },
    surfaceSlim: {
      border: `1px solid ${theme.palette.divider}`
    },
    title: {
      marginBottom: theme.spacing(2)
    },
    outerTitle: {
      marginBottom: theme.spacing(4)
    },
    collapse: {
      width: "100%",
    }
  })
);

export default function Surface(props) {
  const { xs, sm, md, lg, xl, xxl, title, collapse, className, header, outerClassName, collapseClassName, slim } = props;
  const { classes } = useStyles();

  const [hidden, setHidden] = useState(typeof collapse === "boolean" ? collapse : false);

  useEffect(() => {
    if (collapse) {
      const timer = setTimeout(() => setHidden(true), 300);
      return () => {
        clearTimeout(timer);
        setHidden(false);
      }
    } else if (collapse === false) {
      setHidden(false);
    }
  }, [collapse]);

  return hidden && collapse === true ? null : (
    <Grid item container xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl} direction="row" className={outerClassName}>
      {header}
      <Collapse in={!collapse && !hidden} className={`${classes.collapse} ${collapseClassName || ""}`}>
        <Grid item container direction="row" justifyContent="flex-start" alignItems="flex-start" className={`${classes.container} ${slim ? classes.surfaceSlim : ""} ${className}`}>
          {!!title ? (
            <Grid item xs={12}>
              {
                typeof title === "string" ? (
                  <Typography variant="h5" className={classes.title}>{title}</Typography>
                ) : title
              }
            </Grid>
          ) : null}
          {props.children}
        </Grid>
      </Collapse>
    </Grid>
  );
}

export function ToggleSurface(props) {
  const { xs, sm, md, lg, xl, xxl, title, collapse, className, header, outerClassName, surface } = props;
  const { classes } = useStyles();
  const { slim } = useDevice();
  const showSurface = typeof surface === "boolean" ? surface : !slim;

  return showSurface ? (
    <Surface
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
      xxl={xxl}
      title={title}
      collapse={collapse}
      className={className}
      header={header}
      outerClassName={outerClassName}
      slim={slim}
    >
      {props.children}
    </Surface>
  ) : (
    collapse === true ? null : (
      <Grid
        item
        container direction="row" justifyContent="flex-start" alignItems="flex-start"
        xs={xs}
        sm={sm}
        md={md}
        lg={lg}
        xl={xl}
        xxl={xxl}
        className={outerClassName}
      >
        {!!title ? (
          <Grid item xs={12}>
            {
              typeof title === "string" ? (
                <Typography variant="h5" className={classes.title}>{title}</Typography>
              ) : title
            }
          </Grid>
        ) : null}
        {props.children}
      </Grid>
    )
  );
}