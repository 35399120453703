import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import MapboxImage from "../core/MapboxImage";
import { useCallback, useMemo, useState } from "react";
import mapboxgl from "mapbox-gl";
import axios from "axios";
import { MapboxPickerToLocation } from "../../../common/maphelpers";

const useStyles = makeStyles()(
  (theme) => ({
    image: {
      width: "100%",
      height: "auto",
      aspectRatio: "1 / 1",
      borderRadius: theme.spacing(2),
      marginTop: theme.spacing(2),
      boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.15)",
      border: `1px solid ${theme.palette.divider}`,
    },
    actions: {
      width: "100%"
    }
  })
);

export default function UpdateLocationDialog(props) {
  const { task, geolocation, waypoint } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);

  const geojson = useMemo(() => {

    return {
      "type": "FeatureCollection",
      "features": [
        {
          "type": "Feature",
          "properties": {
            "marker-color": theme.palette.info.dark,
            "marker-size": "large",
            "marker-symbol": "car"
          },
          "geometry": {
            "type": "Point",
            "coordinates": [
              geolocation.coords.longitude,
              geolocation.coords.latitude
            ]
          }
        },
        !!waypoint?.coordinates ? {
          "type": "Feature",
          "properties": {
            "marker-color": theme.palette.info.light,
            "marker-size": "large",
            "marker-symbol": "road-accident"
          },
          "geometry": {
            "type": "Point",
            "coordinates": waypoint.coordinates
          }
        } : null
      ].filter(t => !!t)
    }
  }, [geolocation, waypoint, theme]);


  const onComplete = useCallback(async () => {
    setLoading(true);
    const coords = [geolocation.coords.longitude, geolocation.coords.latitude];

    try {
      const res = await axios(`https://api.mapbox.com/geocoding/v5/mapbox.places/${coords[0]},${coords[1]}.json?session_token=${"test"}&access_token=${mapboxgl.accessToken}`)
      const { features } = res.data;

      if (features.length > 0) {
        const feature = features[0];
        task.complete(MapboxPickerToLocation(coords, feature));
      } else {
        task.complete(new Location({
          coordinates: coords,
          title: coords.join(", "),
          address: coords.join(", "),
        }));
      }

    } catch (err) {
      console.log(err);
      task.complete(new Location({
        coordinates: coords,
        title: coords.join(", "),
        address: coords.join(", "),
      }));
    }
  }, [task]);

  return (
    <Dialog
      open
      onClose={() => task.complete("cancel")}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        {t("updateLocationDialog:title")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("updateLocationDialog:description")}
        </DialogContentText>

        <MapboxImage geojson={geojson} className={classes.image} />

      </DialogContent>
      <DialogActions>
        <Stack className={classes.actions} spacing={1}>
          <Button variant="contained" onClick={onComplete} fullWidth size="large" disabled={loading}>
            {t("updateLocationDialog:confirmText")}
          </Button>
          <Button onClick={() => task.throw()} fullWidth size="large" disabled={loading}>
            {t("updateLocationDialog:cancelText")}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}