import { Grid, Button, MenuItem, FormControl, Dialog, DialogTitle, DialogContent, DialogActions, InputLabel, Select, Divider, Typography, ListSubheader, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { StatusCode, useEntities, } from "@emberly/zenith-client";
import { useMemo, useState } from "react";
import { ExportEnums, ExportEnumsLists, } from "../../../../common/constants";
import { IntegrationLibrary } from "../../../pages/integrations/common/library";

const FILE_FORMATS_LEN = ExportEnumsLists.InvoiceExportFormats.length;

export default function InvoiceExportDialog(props) {
  const { exportAction, working, dialogAction, exportResult } = props;
  const onClose = () => !!exportResult ? dialogAction.complete(false) : exportAction.throw("cancelled");
  const [name, setName] = useState(null);

  return (
    <Dialog
      open
      onClose={!working ? onClose : undefined}
      fullWidth
      maxWidth="xs"
    >

      {
        working ? (
          <ExportWorking />
        ) : !exportResult ? (
          <ExportSetup exportAction={exportAction} setName={setName} />
        ) : (
          <ExportResult dialogAction={dialogAction} exportResult={exportResult} name={name} />
        )
      }

    </Dialog>
  );
}



function ExportSetup(props) {
  const { exportAction, setName } = props;

  const { t } = useTranslation();
  const [format, setFormat] = useState(ExportEnums.InvoiceExportFormats.SO01);
  const { entities: integrationEntities, loading } = useEntities("Integration");

  const integrations = useMemo(
    () => integrationEntities.filter(t => !!IntegrationLibrary.get(t.serviceType)?.capabilities?.ImportInvoiceOrders),
    [integrationEntities]
  );

  const onStartExport = async () => {
    if (format < FILE_FORMATS_LEN) {
      exportAction.complete({ exportType: "file", format, friendlyName: ExportEnumsLists.InvoiceExportFormats[format] });
    } else {
      const integrationIndex = format - FILE_FORMATS_LEN;
      const integration = integrations[integrationIndex];
      setName(integration.serviceName);
      exportAction.complete({ exportType: "integration", integration, friendlyName: integration.friendlyName });
    }
  };

  const onClose = () => exportAction.throw("cancelled");

  return (
    <>
      <DialogTitle>
        {t("order:invoiceFlow:exportDialog:title")}
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>


          <Grid item xs={12}>
            <Typography variant="body2">
              {t("order:invoiceFlow:exportDialog:description")}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth size="small" variant="filled">
              <InputLabel>{t("invoiceExport:exportSelect")}</InputLabel>
              <Select onChange={(ev) => setFormat(ev.target.value)} value={format} disabled={loading}>

                <ListSubheader>{t("invoiceExport:filesSubHeader")}</ListSubheader>
                {ExportEnumsLists.InvoiceExportFormats.map((type, value) => <MenuItem value={value} key={value}>{type}</MenuItem>)}

                {!!integrations.length ? <Divider /> : null}

                {!!integrations.length ? <ListSubheader>{t("invoiceExport:integrationsSubHeader")}</ListSubheader> : null}
                {integrations.map((ig, value) => <MenuItem value={value + FILE_FORMATS_LEN} key={value}>{ig.friendlyName}</MenuItem>)}

              </Select>
            </FormControl>
          </Grid>


        </Grid>
      </DialogContent>


      <DialogActions>
        <Button onClick={onClose}>
          {t("common:cancel")}
        </Button>
        <Button variant="contained" color="primary" onClick={onStartExport} disabled={false}>
          {t("order:invoiceFlow:exportDialog:confirmText")}
        </Button>
      </DialogActions>
    </>
  );
}

function ExportResult(props) {
  const { dialogAction, exportResult, name } = props;
  const { t } = useTranslation();

  const success = exportResult.statusCode === StatusCode.Success

  const onClose = () => dialogAction.complete(false);
  const onOpenResultPage = () => dialogAction.complete(true);

  return (
    <>
      <DialogTitle>
        {success ? t("order:invoiceFlow:exportDialog:successTitle") : t("order:invoiceFlow:exportDialog:errorTitle")}
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>

          <Grid item xs={12}>
            {success ? (
              <>
                <Typography variant="body1">
                  {t("order:invoiceFlow:exportDialog:successDescription")}
                </Typography>
                {!!exportResult?.value?.[0]?.actions?.length > 0 ? (
                  <ul style={{ margin: 0, paddingLeft: "24px", marginTop: "8px"}}>
                    {exportResult.value[0].actions.map((action, key) => <li key={key}><Typography variant="body1">{action}</Typography></li>)}
                  </ul>
                ) : null}
              </>
            ) : (
              <Typography variant="body1">
                {t("order:invoiceFlow:exportDialog:errorDescription")}
                <br />
                "{exportResult?.value ?
                  ((exportResult.value.detail || exportResult.value.title || "").replace(/[^A-Z\d\s.:æøåÆØÅ]/gi, '')) :
                  !!exportResult?.message ? t(`integrations:library:${name}:errorDescriptions:${exportResult.message}`) : t("order:invoiceFlow:exportDialog:unknownError")
                }"
                <br />
                <br />
                {t("order:invoiceFlow:exportDialog:pleaseFix")}
              </Typography>
            )}

          </Grid>

        </Grid>
      </DialogContent>

      <DialogActions>
        {
          success ? (
            <Button onClick={onOpenResultPage}>
              {t("order:invoiceFlow:exportDialog:openJournal")}
            </Button>
          ) : null
        }
        <Button variant="contained" color={success ? "success" : "error"} onClick={onClose}>
          {success ? t("order:invoiceFlow:exportDialog:close") : t("order:invoiceFlow:exportDialog:ok")}
        </Button>
      </DialogActions>
    </>
  );
}






function ExportWorking() {
  const { t } = useTranslation();

  return (
    <>
      <DialogTitle>
        {t("order:invoiceFlow:exportDialog:workingTitle")}
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} container justifyContent="center">
            <CircularProgress variant="indeterminate" size="64px" style={{ marginTop: "44px", marginBottom: "44px" }} />
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
}