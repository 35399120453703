import { Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEntity, useEntityField, Defaults, useAuth, Task } from "@emberly/zenith-client";
import { CalcToPaySum, GetUserReference, UpdateSender } from "../../../../common/orders";
import { useCallback, useState } from "react";
import moment from "moment/moment";
import TerminalInformationCard from "./information/TerminalInformationCard";
import ReceiptDialog from "../dialogs/ReceiptDialog";
import useReceipt from "../../../../hooks/useReceipt";
import ConfirmDialog from "../../inputs/ConfirmDialog";
import { useOrder } from "../../../../providers/OrderProvider";
import { ActivityEnums, OrderEnums } from "../../../../common/constants";
import { useMission } from "../../../../providers/MissionProvider";
import { useStation } from "../../../../providers/StationProvider";
import { MakeActivity } from "../../../../common/activity";
import MultilineButton from "../../inputs/MultilineButton";

export default function TerminalFlow() {
  const { t } = useTranslation();
  const { updateEntityField, entity } = useEntity();
  const { user } = useAuth();
  const { sharePDF } = useReceipt();
  const { order, hasValue } = useOrder();
  const { orders } = useMission();
  const { priceUnit, logEvent } = useStation();


  const comment = useEntityField("payment.comment", Defaults.String);
  const confirmed = useEntityField("payment.terminal.confirmed", Defaults.Boolean);
  const service = useEntityField("payment.terminal.service", Defaults.String);

  const [showReceipt, setShowReceipt] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);

  const onConfirm = useCallback(async () => {
    try {

      const t = new Task();
      setConfirmAction(t);
      await t.wait();

      confirmed.onChange(null, true);
      updateEntityField("payment.terminal.confirmedDate", moment.utc().toISOString());
      updateEntityField("payment.terminal.confirmedBy", GetUserReference(user));
      updateEntityField("payment.calculatedTotal", { value: CalcToPaySum(order, orders), currency: priceUnit });
      UpdateSender(user, updateEntityField);

      logEvent(MakeActivity(ActivityEnums.Category.Billing, ActivityEnums.Type.PaymentConfirmed, `${entity?.missionId}/${entity?.id}`));

    } catch { } finally {
      setConfirmAction(null);
    }
  }, [updateEntityField, confirmed, user, orders, order, priceUnit, logEvent, entity]);

  return ( // TODO try removing grid
    <>
      {
        !confirmed.value ? (
          <>
            {
              order?.payment?.method === OrderEnums.PaymentMethod.MobilePayment ? (
                <Grid item xs={12}>
                  <TextField
                    label={t("order:terminalFlow:service")}
                    variant="filled"
                    multiline
                    fullWidth
                    size="small"
                    value={service.value}
                    onChange={service.onChange}
                  />
                </Grid>
              ) : null
            }

            <Grid item xs={12}>
              <TextField
                label={t("order:terminalFlow:comment")}
                variant="filled"
                multiline
                fullWidth
                size="small"
                value={comment.value}
                onChange={comment.onChange}
              />
            </Grid>

            <Grid item xs={12}>
              <MultilineButton fullWidth variant="contained" color="primary" onClick={onConfirm} disabled={!hasValue}>
                {t("order:terminalFlow:confirmPayment")}
              </MultilineButton>
            </Grid>

          </>
        ) : (
          <>
            <Grid item xs={12}>
              <TerminalInformationCard />
            </Grid>

            <Grid item xs={6}>
              <MultilineButton fullWidth variant="outlined" color="primary" onClick={() => setShowReceipt(true)}>
                {t("order:terminalFlow:viewReceipt")}
              </MultilineButton>
            </Grid>

            <Grid item xs={6}>
              <MultilineButton fullWidth variant="contained" color="primary" onClick={sharePDF}>
                {t("order:terminalFlow:sendReceipt")}
              </MultilineButton>
            </Grid>
          </>
        )
      }

      {showReceipt ? (
        <ReceiptDialog onClose={() => setShowReceipt(false)} />
      ) : null}

      <ConfirmDialog
        task={confirmAction}
        title={t("order:paymentConfirmedDialog:title")}
        description={t("order:paymentConfirmedDialog:description")}
        confirmText={t("order:paymentConfirmedDialog:confirmText")}
      />

    </>
  );
}
